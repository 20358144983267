export function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
export function getAttr(obj, path, defaultInit) {
    return path.reduce((acc, cur) => {
        if (acc && cur in acc)
            return acc[cur];
        else if (defaultInit)
            return defaultInit();
        else
            return undefined;
    }, obj);
}
export class DefaultDict {
    constructor(defaultInit) {
        return new Proxy({}, {
            get: (target, name) => {
                if (name in target) {
                    return target[name];
                }
                else if (typeof defaultInit === "function") {
                    target[name] = new defaultInit().valueOf();
                    return target[name];
                }
                else {
                    return defaultInit;
                }
            },
        });
    }
}
export function tree() {
    return new Proxy({}, {
        get: (target, name) => {
            if (name in target) {
                return target[name];
            }
            else {
                target[name] = tree();
                return target[name];
            }
        },
    });
}
export class IntervalTimer {
    constructor(fn, time) {
        this.fn = fn;
        this.time = time;
    }
    stop() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = undefined;
        }
        return this;
    }
    start() {
        if (!this.timer) {
            this.stop();
            this.timer = setInterval(this.fn, this.time);
        }
        return this;
    }
    reset(newTime = this.time) {
        this.time = newTime;
        return this.stop().start();
    }
}
export function getRandomInt(min, max) {
    //The maximum is exclusive and the minimum is inclusive
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}
