import firebase from "firebase/app";
import { encrypt, decrypt } from "./crypto";
import { EventBusSingleton as EventBus } from "light-event-bus";
import * as errors from "./errors";
import LZString from "lz-string";
let firestoreUnsubscriber;
export function firestoreUnsubscribe() {
    if (firestoreUnsubscriber)
        firestoreUnsubscriber();
}
export function firestoreListen(roomId, toId, toSecretKey) {
    firestoreUnsubscribe();
    firestoreUnsubscriber = firebase
        .firestore()
        .collection(`/rooms/${roomId}/msgs`)
        .where("to", "==", toId)
        .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(function (change) {
            if (change.type === "added") {
                const msg = change.doc.data();
                // msg["payload"] = JSON.parse(decrypt(toSecretKey, msg["payload"]));
                msg["payload"] = JSON.parse(decrypt(toSecretKey, LZString.decompressFromUTF16(msg["payload"]) || ""));
                change.doc.ref.delete();
                EventBus.publish(msg.method, msg);
            }
        });
    }, (err) => {
        console.log(err);
        return errors.fatal(errors.fatalEnum.FIRESTORE_ERROR);
    });
}
function firestoreSend(roomId, fromId, toId, toPublicKey, method, payload) {
    if (fromId == toId)
        return;
    firebase
        .firestore()
        .collection(`/rooms/${roomId}/msgs`)
        .add({
        from: fromId,
        to: toId,
        method: method,
        // payload: encrypt(toPublicKey, JSON.stringify(payload)),
        payload: LZString.compressToUTF16(encrypt(toPublicKey, JSON.stringify(payload))),
    })
        .catch((err) => {
        console.log(err);
        return errors.fatal(errors.fatalEnum.FIRESTORE_ERROR);
    });
}
export function dataChannelListen(players, fromId, myId) {
    players[fromId]["peerConnection"].on("data", (data) => {
        players[fromId]["peerConnectionListenBeats"].reset();
        const msg = JSON.parse(data);
        if (msg["to"] == myId) {
            EventBus.publish(msg.method, msg);
        }
        else {
            dataChannelSend(players, msg["to"], msg["from"], msg["to"], msg["method"], msg["payload"]);
        }
    });
}
function dataChannelSend(players, proxyId, fromId, toId, method, payload) {
    players[proxyId]["peerConnectionSendBeats"].reset();
    players[proxyId]["peerConnection"].send(JSON.stringify({
        from: fromId,
        to: toId,
        method: method,
        payload: payload,
    }));
}
export function send(players, roomId, fromId, toId, method, payload) {
    if (fromId == toId)
        return;
    if (players[toId]["peerConnected"]) {
        dataChannelSend(players, toId, fromId, toId, method, payload);
    }
    else if (players["host"]["peerConnected"]) {
        dataChannelSend(players, "host", fromId, toId, method, payload);
    }
    else if (players[toId]["publicKey"]) {
        firestoreSend(roomId, fromId, toId, players[toId]["publicKey"], method, payload);
    }
}
export function sendAll(players, roomId, fromId, toIds, method, payload) {
    toIds.forEach((toId) => {
        if (toId == fromId)
            return;
        send(players, roomId, fromId, toId, method, payload);
    });
}
