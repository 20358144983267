import { DefaultDict } from "./utils";
export const avatars = ["elephant", "giraffe", "monkey", "rabbit"];
export const teams = ["red", "blue"];
export const teambg = {
    red: "bg-red-300",
    blue: "bg-blue-300",
};
export const players = new DefaultDict(Object);
export const state = {};
export const standardStartingPosition = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";
export const fischerStartingPositions = [
    "bbqnnrkr/pppppppp/8/8/8/8/PPPPPPPP/BBQNNRKR w - - 0 1",
    "bqnbnrkr/pppppppp/8/8/8/8/PPPPPPPP/BQNBNRKR w - - 0 1",
    "bqnnrbkr/pppppppp/8/8/8/8/PPPPPPPP/BQNNRBKR w - - 0 1",
    "bqnnrkrb/pppppppp/8/8/8/8/PPPPPPPP/BQNNRKRB w - - 0 1",
    "qbbnnrkr/pppppppp/8/8/8/8/PPPPPPPP/QBBNNRKR w - - 0 1",
    "qnbbnrkr/pppppppp/8/8/8/8/PPPPPPPP/QNBBNRKR w - - 0 1",
    "qnbnrbkr/pppppppp/8/8/8/8/PPPPPPPP/QNBNRBKR w - - 0 1",
    "qnbnrkrb/pppppppp/8/8/8/8/PPPPPPPP/QNBNRKRB w - - 0 1",
    "qbnnbrkr/pppppppp/8/8/8/8/PPPPPPPP/QBNNBRKR w - - 0 1",
    "qnnbbrkr/pppppppp/8/8/8/8/PPPPPPPP/QNNBBRKR w - - 0 1",
    "qnnrbbkr/pppppppp/8/8/8/8/PPPPPPPP/QNNRBBKR w - - 0 1",
    "qnnrbkrb/pppppppp/8/8/8/8/PPPPPPPP/QNNRBKRB w - - 0 1",
    "qbnnrkbr/pppppppp/8/8/8/8/PPPPPPPP/QBNNRKBR w - - 0 1",
    "qnnbrkbr/pppppppp/8/8/8/8/PPPPPPPP/QNNBRKBR w - - 0 1",
    "qnnrkbbr/pppppppp/8/8/8/8/PPPPPPPP/QNNRKBBR w - - 0 1",
    "qnnrkrbb/pppppppp/8/8/8/8/PPPPPPPP/QNNRKRBB w - - 0 1",
    "bbnqnrkr/pppppppp/8/8/8/8/PPPPPPPP/BBNQNRKR w - - 0 1",
    "bnqbnrkr/pppppppp/8/8/8/8/PPPPPPPP/BNQBNRKR w - - 0 1",
    "bnqnrbkr/pppppppp/8/8/8/8/PPPPPPPP/BNQNRBKR w - - 0 1",
    "bnqnrkrb/pppppppp/8/8/8/8/PPPPPPPP/BNQNRKRB w - - 0 1",
    "nbbqnrkr/pppppppp/8/8/8/8/PPPPPPPP/NBBQNRKR w - - 0 1",
    "nqbbnrkr/pppppppp/8/8/8/8/PPPPPPPP/NQBBNRKR w - - 0 1",
    "nqbnrbkr/pppppppp/8/8/8/8/PPPPPPPP/NQBNRBKR w - - 0 1",
    "nqbnrkrb/pppppppp/8/8/8/8/PPPPPPPP/NQBNRKRB w - - 0 1",
    "nbqnbrkr/pppppppp/8/8/8/8/PPPPPPPP/NBQNBRKR w - - 0 1",
    "nqnbbrkr/pppppppp/8/8/8/8/PPPPPPPP/NQNBBRKR w - - 0 1",
    "nqnrbbkr/pppppppp/8/8/8/8/PPPPPPPP/NQNRBBKR w - - 0 1",
    "nqnrbkrb/pppppppp/8/8/8/8/PPPPPPPP/NQNRBKRB w - - 0 1",
    "nbqnrkbr/pppppppp/8/8/8/8/PPPPPPPP/NBQNRKBR w - - 0 1",
    "nqnbrkbr/pppppppp/8/8/8/8/PPPPPPPP/NQNBRKBR w - - 0 1",
    "nqnrkbbr/pppppppp/8/8/8/8/PPPPPPPP/NQNRKBBR w - - 0 1",
    "nqnrkrbb/pppppppp/8/8/8/8/PPPPPPPP/NQNRKRBB w - - 0 1",
    "bbnnqrkr/pppppppp/8/8/8/8/PPPPPPPP/BBNNQRKR w - - 0 1",
    "bnnbqrkr/pppppppp/8/8/8/8/PPPPPPPP/BNNBQRKR w - - 0 1",
    "bnnqrbkr/pppppppp/8/8/8/8/PPPPPPPP/BNNQRBKR w - - 0 1",
    "bnnqrkrb/pppppppp/8/8/8/8/PPPPPPPP/BNNQRKRB w - - 0 1",
    "nbbnqrkr/pppppppp/8/8/8/8/PPPPPPPP/NBBNQRKR w - - 0 1",
    "nnbbqrkr/pppppppp/8/8/8/8/PPPPPPPP/NNBBQRKR w - - 0 1",
    "nnbqrbkr/pppppppp/8/8/8/8/PPPPPPPP/NNBQRBKR w - - 0 1",
    "nnbqrkrb/pppppppp/8/8/8/8/PPPPPPPP/NNBQRKRB w - - 0 1",
    "nbnqbrkr/pppppppp/8/8/8/8/PPPPPPPP/NBNQBRKR w - - 0 1",
    "nnqbbrkr/pppppppp/8/8/8/8/PPPPPPPP/NNQBBRKR w - - 0 1",
    "nnqrbbkr/pppppppp/8/8/8/8/PPPPPPPP/NNQRBBKR w - - 0 1",
    "nnqrbkrb/pppppppp/8/8/8/8/PPPPPPPP/NNQRBKRB w - - 0 1",
    "nbnqrkbr/pppppppp/8/8/8/8/PPPPPPPP/NBNQRKBR w - - 0 1",
    "nnqbrkbr/pppppppp/8/8/8/8/PPPPPPPP/NNQBRKBR w - - 0 1",
    "nnqrkbbr/pppppppp/8/8/8/8/PPPPPPPP/NNQRKBBR w - - 0 1",
    "nnqrkrbb/pppppppp/8/8/8/8/PPPPPPPP/NNQRKRBB w - - 0 1",
    "bbnnrqkr/pppppppp/8/8/8/8/PPPPPPPP/BBNNRQKR w - - 0 1",
    "bnnbrqkr/pppppppp/8/8/8/8/PPPPPPPP/BNNBRQKR w - - 0 1",
    "bnnrqbkr/pppppppp/8/8/8/8/PPPPPPPP/BNNRQBKR w - - 0 1",
    "bnnrqkrb/pppppppp/8/8/8/8/PPPPPPPP/BNNRQKRB w - - 0 1",
    "nbbnrqkr/pppppppp/8/8/8/8/PPPPPPPP/NBBNRQKR w - - 0 1",
    "nnbbrqkr/pppppppp/8/8/8/8/PPPPPPPP/NNBBRQKR w - - 0 1",
    "nnbrqbkr/pppppppp/8/8/8/8/PPPPPPPP/NNBRQBKR w - - 0 1",
    "nnbrqkrb/pppppppp/8/8/8/8/PPPPPPPP/NNBRQKRB w - - 0 1",
    "nbnrbqkr/pppppppp/8/8/8/8/PPPPPPPP/NBNRBQKR w - - 0 1",
    "nnrbbqkr/pppppppp/8/8/8/8/PPPPPPPP/NNRBBQKR w - - 0 1",
    "nnrqbbkr/pppppppp/8/8/8/8/PPPPPPPP/NNRQBBKR w - - 0 1",
    "nnrqbkrb/pppppppp/8/8/8/8/PPPPPPPP/NNRQBKRB w - - 0 1",
    "nbnrqkbr/pppppppp/8/8/8/8/PPPPPPPP/NBNRQKBR w - - 0 1",
    "nnrbqkbr/pppppppp/8/8/8/8/PPPPPPPP/NNRBQKBR w - - 0 1",
    "nnrqkbbr/pppppppp/8/8/8/8/PPPPPPPP/NNRQKBBR w - - 0 1",
    "nnrqkrbb/pppppppp/8/8/8/8/PPPPPPPP/NNRQKRBB w - - 0 1",
    "bbnnrkqr/pppppppp/8/8/8/8/PPPPPPPP/BBNNRKQR w - - 0 1",
    "bnnbrkqr/pppppppp/8/8/8/8/PPPPPPPP/BNNBRKQR w - - 0 1",
    "bnnrkbqr/pppppppp/8/8/8/8/PPPPPPPP/BNNRKBQR w - - 0 1",
    "bnnrkqrb/pppppppp/8/8/8/8/PPPPPPPP/BNNRKQRB w - - 0 1",
    "nbbnrkqr/pppppppp/8/8/8/8/PPPPPPPP/NBBNRKQR w - - 0 1",
    "nnbbrkqr/pppppppp/8/8/8/8/PPPPPPPP/NNBBRKQR w - - 0 1",
    "nnbrkbqr/pppppppp/8/8/8/8/PPPPPPPP/NNBRKBQR w - - 0 1",
    "nnbrkqrb/pppppppp/8/8/8/8/PPPPPPPP/NNBRKQRB w - - 0 1",
    "nbnrbkqr/pppppppp/8/8/8/8/PPPPPPPP/NBNRBKQR w - - 0 1",
    "nnrbbkqr/pppppppp/8/8/8/8/PPPPPPPP/NNRBBKQR w - - 0 1",
    "nnrkbbqr/pppppppp/8/8/8/8/PPPPPPPP/NNRKBBQR w - - 0 1",
    "nnrkbqrb/pppppppp/8/8/8/8/PPPPPPPP/NNRKBQRB w - - 0 1",
    "nbnrkqbr/pppppppp/8/8/8/8/PPPPPPPP/NBNRKQBR w - - 0 1",
    "nnrbkqbr/pppppppp/8/8/8/8/PPPPPPPP/NNRBKQBR w - - 0 1",
    "nnrkqbbr/pppppppp/8/8/8/8/PPPPPPPP/NNRKQBBR w - - 0 1",
    "nnrkqrbb/pppppppp/8/8/8/8/PPPPPPPP/NNRKQRBB w - - 0 1",
    "bbnnrkrq/pppppppp/8/8/8/8/PPPPPPPP/BBNNRKRQ w - - 0 1",
    "bnnbrkrq/pppppppp/8/8/8/8/PPPPPPPP/BNNBRKRQ w - - 0 1",
    "bnnrkbrq/pppppppp/8/8/8/8/PPPPPPPP/BNNRKBRQ w - - 0 1",
    "bnnrkrqb/pppppppp/8/8/8/8/PPPPPPPP/BNNRKRQB w - - 0 1",
    "nbbnrkrq/pppppppp/8/8/8/8/PPPPPPPP/NBBNRKRQ w - - 0 1",
    "nnbbrkrq/pppppppp/8/8/8/8/PPPPPPPP/NNBBRKRQ w - - 0 1",
    "nnbrkbrq/pppppppp/8/8/8/8/PPPPPPPP/NNBRKBRQ w - - 0 1",
    "nnbrkrqb/pppppppp/8/8/8/8/PPPPPPPP/NNBRKRQB w - - 0 1",
    "nbnrbkrq/pppppppp/8/8/8/8/PPPPPPPP/NBNRBKRQ w - - 0 1",
    "nnrbbkrq/pppppppp/8/8/8/8/PPPPPPPP/NNRBBKRQ w - - 0 1",
    "nnrkbbrq/pppppppp/8/8/8/8/PPPPPPPP/NNRKBBRQ w - - 0 1",
    "nnrkbrqb/pppppppp/8/8/8/8/PPPPPPPP/NNRKBRQB w - - 0 1",
    "nbnrkrbq/pppppppp/8/8/8/8/PPPPPPPP/NBNRKRBQ w - - 0 1",
    "nnrbkrbq/pppppppp/8/8/8/8/PPPPPPPP/NNRBKRBQ w - - 0 1",
    "nnrkrbbq/pppppppp/8/8/8/8/PPPPPPPP/NNRKRBBQ w - - 0 1",
    "nnrkrqbb/pppppppp/8/8/8/8/PPPPPPPP/NNRKRQBB w - - 0 1",
    "bbqnrnkr/pppppppp/8/8/8/8/PPPPPPPP/BBQNRNKR w - - 0 1",
    "bqnbrnkr/pppppppp/8/8/8/8/PPPPPPPP/BQNBRNKR w - - 0 1",
    "bqnrnbkr/pppppppp/8/8/8/8/PPPPPPPP/BQNRNBKR w - - 0 1",
    "bqnrnkrb/pppppppp/8/8/8/8/PPPPPPPP/BQNRNKRB w - - 0 1",
    "qbbnrnkr/pppppppp/8/8/8/8/PPPPPPPP/QBBNRNKR w - - 0 1",
    "qnbbrnkr/pppppppp/8/8/8/8/PPPPPPPP/QNBBRNKR w - - 0 1",
    "qnbrnbkr/pppppppp/8/8/8/8/PPPPPPPP/QNBRNBKR w - - 0 1",
    "qnbrnkrb/pppppppp/8/8/8/8/PPPPPPPP/QNBRNKRB w - - 0 1",
    "qbnrbnkr/pppppppp/8/8/8/8/PPPPPPPP/QBNRBNKR w - - 0 1",
    "qnrbbnkr/pppppppp/8/8/8/8/PPPPPPPP/QNRBBNKR w - - 0 1",
    "qnrnbbkr/pppppppp/8/8/8/8/PPPPPPPP/QNRNBBKR w - - 0 1",
    "qnrnbkrb/pppppppp/8/8/8/8/PPPPPPPP/QNRNBKRB w - - 0 1",
    "qbnrnkbr/pppppppp/8/8/8/8/PPPPPPPP/QBNRNKBR w - - 0 1",
    "qnrbnkbr/pppppppp/8/8/8/8/PPPPPPPP/QNRBNKBR w - - 0 1",
    "qnrnkbbr/pppppppp/8/8/8/8/PPPPPPPP/QNRNKBBR w - - 0 1",
    "qnrnkrbb/pppppppp/8/8/8/8/PPPPPPPP/QNRNKRBB w - - 0 1",
    "bbnqrnkr/pppppppp/8/8/8/8/PPPPPPPP/BBNQRNKR w - - 0 1",
    "bnqbrnkr/pppppppp/8/8/8/8/PPPPPPPP/BNQBRNKR w - - 0 1",
    "bnqrnbkr/pppppppp/8/8/8/8/PPPPPPPP/BNQRNBKR w - - 0 1",
    "bnqrnkrb/pppppppp/8/8/8/8/PPPPPPPP/BNQRNKRB w - - 0 1",
    "nbbqrnkr/pppppppp/8/8/8/8/PPPPPPPP/NBBQRNKR w - - 0 1",
    "nqbbrnkr/pppppppp/8/8/8/8/PPPPPPPP/NQBBRNKR w - - 0 1",
    "nqbrnbkr/pppppppp/8/8/8/8/PPPPPPPP/NQBRNBKR w - - 0 1",
    "nqbrnkrb/pppppppp/8/8/8/8/PPPPPPPP/NQBRNKRB w - - 0 1",
    "nbqrbnkr/pppppppp/8/8/8/8/PPPPPPPP/NBQRBNKR w - - 0 1",
    "nqrbbnkr/pppppppp/8/8/8/8/PPPPPPPP/NQRBBNKR w - - 0 1",
    "nqrnbbkr/pppppppp/8/8/8/8/PPPPPPPP/NQRNBBKR w - - 0 1",
    "nqrnbkrb/pppppppp/8/8/8/8/PPPPPPPP/NQRNBKRB w - - 0 1",
    "nbqrnkbr/pppppppp/8/8/8/8/PPPPPPPP/NBQRNKBR w - - 0 1",
    "nqrbnkbr/pppppppp/8/8/8/8/PPPPPPPP/NQRBNKBR w - - 0 1",
    "nqrnkbbr/pppppppp/8/8/8/8/PPPPPPPP/NQRNKBBR w - - 0 1",
    "nqrnkrbb/pppppppp/8/8/8/8/PPPPPPPP/NQRNKRBB w - - 0 1",
    "bbnrqnkr/pppppppp/8/8/8/8/PPPPPPPP/BBNRQNKR w - - 0 1",
    "bnrbqnkr/pppppppp/8/8/8/8/PPPPPPPP/BNRBQNKR w - - 0 1",
    "bnrqnbkr/pppppppp/8/8/8/8/PPPPPPPP/BNRQNBKR w - - 0 1",
    "bnrqnkrb/pppppppp/8/8/8/8/PPPPPPPP/BNRQNKRB w - - 0 1",
    "nbbrqnkr/pppppppp/8/8/8/8/PPPPPPPP/NBBRQNKR w - - 0 1",
    "nrbbqnkr/pppppppp/8/8/8/8/PPPPPPPP/NRBBQNKR w - - 0 1",
    "nrbqnbkr/pppppppp/8/8/8/8/PPPPPPPP/NRBQNBKR w - - 0 1",
    "nrbqnkrb/pppppppp/8/8/8/8/PPPPPPPP/NRBQNKRB w - - 0 1",
    "nbrqbnkr/pppppppp/8/8/8/8/PPPPPPPP/NBRQBNKR w - - 0 1",
    "nrqbbnkr/pppppppp/8/8/8/8/PPPPPPPP/NRQBBNKR w - - 0 1",
    "nrqnbbkr/pppppppp/8/8/8/8/PPPPPPPP/NRQNBBKR w - - 0 1",
    "nrqnbkrb/pppppppp/8/8/8/8/PPPPPPPP/NRQNBKRB w - - 0 1",
    "nbrqnkbr/pppppppp/8/8/8/8/PPPPPPPP/NBRQNKBR w - - 0 1",
    "nrqbnkbr/pppppppp/8/8/8/8/PPPPPPPP/NRQBNKBR w - - 0 1",
    "nrqnkbbr/pppppppp/8/8/8/8/PPPPPPPP/NRQNKBBR w - - 0 1",
    "nrqnkrbb/pppppppp/8/8/8/8/PPPPPPPP/NRQNKRBB w - - 0 1",
    "bbnrnqkr/pppppppp/8/8/8/8/PPPPPPPP/BBNRNQKR w - - 0 1",
    "bnrbnqkr/pppppppp/8/8/8/8/PPPPPPPP/BNRBNQKR w - - 0 1",
    "bnrnqbkr/pppppppp/8/8/8/8/PPPPPPPP/BNRNQBKR w - - 0 1",
    "bnrnqkrb/pppppppp/8/8/8/8/PPPPPPPP/BNRNQKRB w - - 0 1",
    "nbbrnqkr/pppppppp/8/8/8/8/PPPPPPPP/NBBRNQKR w - - 0 1",
    "nrbbnqkr/pppppppp/8/8/8/8/PPPPPPPP/NRBBNQKR w - - 0 1",
    "nrbnqbkr/pppppppp/8/8/8/8/PPPPPPPP/NRBNQBKR w - - 0 1",
    "nrbnqkrb/pppppppp/8/8/8/8/PPPPPPPP/NRBNQKRB w - - 0 1",
    "nbrnbqkr/pppppppp/8/8/8/8/PPPPPPPP/NBRNBQKR w - - 0 1",
    "nrnbbqkr/pppppppp/8/8/8/8/PPPPPPPP/NRNBBQKR w - - 0 1",
    "nrnqbbkr/pppppppp/8/8/8/8/PPPPPPPP/NRNQBBKR w - - 0 1",
    "nrnqbkrb/pppppppp/8/8/8/8/PPPPPPPP/NRNQBKRB w - - 0 1",
    "nbrnqkbr/pppppppp/8/8/8/8/PPPPPPPP/NBRNQKBR w - - 0 1",
    "nrnbqkbr/pppppppp/8/8/8/8/PPPPPPPP/NRNBQKBR w - - 0 1",
    "nrnqkbbr/pppppppp/8/8/8/8/PPPPPPPP/NRNQKBBR w - - 0 1",
    "nrnqkrbb/pppppppp/8/8/8/8/PPPPPPPP/NRNQKRBB w - - 0 1",
    "bbnrnkqr/pppppppp/8/8/8/8/PPPPPPPP/BBNRNKQR w - - 0 1",
    "bnrbnkqr/pppppppp/8/8/8/8/PPPPPPPP/BNRBNKQR w - - 0 1",
    "bnrnkbqr/pppppppp/8/8/8/8/PPPPPPPP/BNRNKBQR w - - 0 1",
    "bnrnkqrb/pppppppp/8/8/8/8/PPPPPPPP/BNRNKQRB w - - 0 1",
    "nbbrnkqr/pppppppp/8/8/8/8/PPPPPPPP/NBBRNKQR w - - 0 1",
    "nrbbnkqr/pppppppp/8/8/8/8/PPPPPPPP/NRBBNKQR w - - 0 1",
    "nrbnkbqr/pppppppp/8/8/8/8/PPPPPPPP/NRBNKBQR w - - 0 1",
    "nrbnkqrb/pppppppp/8/8/8/8/PPPPPPPP/NRBNKQRB w - - 0 1",
    "nbrnbkqr/pppppppp/8/8/8/8/PPPPPPPP/NBRNBKQR w - - 0 1",
    "nrnbbkqr/pppppppp/8/8/8/8/PPPPPPPP/NRNBBKQR w - - 0 1",
    "nrnkbbqr/pppppppp/8/8/8/8/PPPPPPPP/NRNKBBQR w - - 0 1",
    "nrnkbqrb/pppppppp/8/8/8/8/PPPPPPPP/NRNKBQRB w - - 0 1",
    "nbrnkqbr/pppppppp/8/8/8/8/PPPPPPPP/NBRNKQBR w - - 0 1",
    "nrnbkqbr/pppppppp/8/8/8/8/PPPPPPPP/NRNBKQBR w - - 0 1",
    "nrnkqbbr/pppppppp/8/8/8/8/PPPPPPPP/NRNKQBBR w - - 0 1",
    "nrnkqrbb/pppppppp/8/8/8/8/PPPPPPPP/NRNKQRBB w - - 0 1",
    "bbnrnkrq/pppppppp/8/8/8/8/PPPPPPPP/BBNRNKRQ w - - 0 1",
    "bnrbnkrq/pppppppp/8/8/8/8/PPPPPPPP/BNRBNKRQ w - - 0 1",
    "bnrnkbrq/pppppppp/8/8/8/8/PPPPPPPP/BNRNKBRQ w - - 0 1",
    "bnrnkrqb/pppppppp/8/8/8/8/PPPPPPPP/BNRNKRQB w - - 0 1",
    "nbbrnkrq/pppppppp/8/8/8/8/PPPPPPPP/NBBRNKRQ w - - 0 1",
    "nrbbnkrq/pppppppp/8/8/8/8/PPPPPPPP/NRBBNKRQ w - - 0 1",
    "nrbnkbrq/pppppppp/8/8/8/8/PPPPPPPP/NRBNKBRQ w - - 0 1",
    "nrbnkrqb/pppppppp/8/8/8/8/PPPPPPPP/NRBNKRQB w - - 0 1",
    "nbrnbkrq/pppppppp/8/8/8/8/PPPPPPPP/NBRNBKRQ w - - 0 1",
    "nrnbbkrq/pppppppp/8/8/8/8/PPPPPPPP/NRNBBKRQ w - - 0 1",
    "nrnkbbrq/pppppppp/8/8/8/8/PPPPPPPP/NRNKBBRQ w - - 0 1",
    "nrnkbrqb/pppppppp/8/8/8/8/PPPPPPPP/NRNKBRQB w - - 0 1",
    "nbrnkrbq/pppppppp/8/8/8/8/PPPPPPPP/NBRNKRBQ w - - 0 1",
    "nrnbkrbq/pppppppp/8/8/8/8/PPPPPPPP/NRNBKRBQ w - - 0 1",
    "nrnkrbbq/pppppppp/8/8/8/8/PPPPPPPP/NRNKRBBQ w - - 0 1",
    "nrnkrqbb/pppppppp/8/8/8/8/PPPPPPPP/NRNKRQBB w - - 0 1",
    "bbqnrknr/pppppppp/8/8/8/8/PPPPPPPP/BBQNRKNR w - - 0 1",
    "bqnbrknr/pppppppp/8/8/8/8/PPPPPPPP/BQNBRKNR w - - 0 1",
    "bqnrkbnr/pppppppp/8/8/8/8/PPPPPPPP/BQNRKBNR w - - 0 1",
    "bqnrknrb/pppppppp/8/8/8/8/PPPPPPPP/BQNRKNRB w - - 0 1",
    "qbbnrknr/pppppppp/8/8/8/8/PPPPPPPP/QBBNRKNR w - - 0 1",
    "qnbbrknr/pppppppp/8/8/8/8/PPPPPPPP/QNBBRKNR w - - 0 1",
    "qnbrkbnr/pppppppp/8/8/8/8/PPPPPPPP/QNBRKBNR w - - 0 1",
    "qnbrknrb/pppppppp/8/8/8/8/PPPPPPPP/QNBRKNRB w - - 0 1",
    "qbnrbknr/pppppppp/8/8/8/8/PPPPPPPP/QBNRBKNR w - - 0 1",
    "qnrbbknr/pppppppp/8/8/8/8/PPPPPPPP/QNRBBKNR w - - 0 1",
    "qnrkbbnr/pppppppp/8/8/8/8/PPPPPPPP/QNRKBBNR w - - 0 1",
    "qnrkbnrb/pppppppp/8/8/8/8/PPPPPPPP/QNRKBNRB w - - 0 1",
    "qbnrknbr/pppppppp/8/8/8/8/PPPPPPPP/QBNRKNBR w - - 0 1",
    "qnrbknbr/pppppppp/8/8/8/8/PPPPPPPP/QNRBKNBR w - - 0 1",
    "qnrknbbr/pppppppp/8/8/8/8/PPPPPPPP/QNRKNBBR w - - 0 1",
    "qnrknrbb/pppppppp/8/8/8/8/PPPPPPPP/QNRKNRBB w - - 0 1",
    "bbnqrknr/pppppppp/8/8/8/8/PPPPPPPP/BBNQRKNR w - - 0 1",
    "bnqbrknr/pppppppp/8/8/8/8/PPPPPPPP/BNQBRKNR w - - 0 1",
    "bnqrkbnr/pppppppp/8/8/8/8/PPPPPPPP/BNQRKBNR w - - 0 1",
    "bnqrknrb/pppppppp/8/8/8/8/PPPPPPPP/BNQRKNRB w - - 0 1",
    "nbbqrknr/pppppppp/8/8/8/8/PPPPPPPP/NBBQRKNR w - - 0 1",
    "nqbbrknr/pppppppp/8/8/8/8/PPPPPPPP/NQBBRKNR w - - 0 1",
    "nqbrkbnr/pppppppp/8/8/8/8/PPPPPPPP/NQBRKBNR w - - 0 1",
    "nqbrknrb/pppppppp/8/8/8/8/PPPPPPPP/NQBRKNRB w - - 0 1",
    "nbqrbknr/pppppppp/8/8/8/8/PPPPPPPP/NBQRBKNR w - - 0 1",
    "nqrbbknr/pppppppp/8/8/8/8/PPPPPPPP/NQRBBKNR w - - 0 1",
    "nqrkbbnr/pppppppp/8/8/8/8/PPPPPPPP/NQRKBBNR w - - 0 1",
    "nqrkbnrb/pppppppp/8/8/8/8/PPPPPPPP/NQRKBNRB w - - 0 1",
    "nbqrknbr/pppppppp/8/8/8/8/PPPPPPPP/NBQRKNBR w - - 0 1",
    "nqrbknbr/pppppppp/8/8/8/8/PPPPPPPP/NQRBKNBR w - - 0 1",
    "nqrknbbr/pppppppp/8/8/8/8/PPPPPPPP/NQRKNBBR w - - 0 1",
    "nqrknrbb/pppppppp/8/8/8/8/PPPPPPPP/NQRKNRBB w - - 0 1",
    "bbnrqknr/pppppppp/8/8/8/8/PPPPPPPP/BBNRQKNR w - - 0 1",
    "bnrbqknr/pppppppp/8/8/8/8/PPPPPPPP/BNRBQKNR w - - 0 1",
    "bnrqkbnr/pppppppp/8/8/8/8/PPPPPPPP/BNRQKBNR w - - 0 1",
    "bnrqknrb/pppppppp/8/8/8/8/PPPPPPPP/BNRQKNRB w - - 0 1",
    "nbbrqknr/pppppppp/8/8/8/8/PPPPPPPP/NBBRQKNR w - - 0 1",
    "nrbbqknr/pppppppp/8/8/8/8/PPPPPPPP/NRBBQKNR w - - 0 1",
    "nrbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/NRBQKBNR w - - 0 1",
    "nrbqknrb/pppppppp/8/8/8/8/PPPPPPPP/NRBQKNRB w - - 0 1",
    "nbrqbknr/pppppppp/8/8/8/8/PPPPPPPP/NBRQBKNR w - - 0 1",
    "nrqbbknr/pppppppp/8/8/8/8/PPPPPPPP/NRQBBKNR w - - 0 1",
    "nrqkbbnr/pppppppp/8/8/8/8/PPPPPPPP/NRQKBBNR w - - 0 1",
    "nrqkbnrb/pppppppp/8/8/8/8/PPPPPPPP/NRQKBNRB w - - 0 1",
    "nbrqknbr/pppppppp/8/8/8/8/PPPPPPPP/NBRQKNBR w - - 0 1",
    "nrqbknbr/pppppppp/8/8/8/8/PPPPPPPP/NRQBKNBR w - - 0 1",
    "nrqknbbr/pppppppp/8/8/8/8/PPPPPPPP/NRQKNBBR w - - 0 1",
    "nrqknrbb/pppppppp/8/8/8/8/PPPPPPPP/NRQKNRBB w - - 0 1",
    "bbnrkqnr/pppppppp/8/8/8/8/PPPPPPPP/BBNRKQNR w - - 0 1",
    "bnrbkqnr/pppppppp/8/8/8/8/PPPPPPPP/BNRBKQNR w - - 0 1",
    "bnrkqbnr/pppppppp/8/8/8/8/PPPPPPPP/BNRKQBNR w - - 0 1",
    "bnrkqnrb/pppppppp/8/8/8/8/PPPPPPPP/BNRKQNRB w - - 0 1",
    "nbbrkqnr/pppppppp/8/8/8/8/PPPPPPPP/NBBRKQNR w - - 0 1",
    "nrbbkqnr/pppppppp/8/8/8/8/PPPPPPPP/NRBBKQNR w - - 0 1",
    "nrbkqbnr/pppppppp/8/8/8/8/PPPPPPPP/NRBKQBNR w - - 0 1",
    "nrbkqnrb/pppppppp/8/8/8/8/PPPPPPPP/NRBKQNRB w - - 0 1",
    "nbrkbqnr/pppppppp/8/8/8/8/PPPPPPPP/NBRKBQNR w - - 0 1",
    "nrkbbqnr/pppppppp/8/8/8/8/PPPPPPPP/NRKBBQNR w - - 0 1",
    "nrkqbbnr/pppppppp/8/8/8/8/PPPPPPPP/NRKQBBNR w - - 0 1",
    "nrkqbnrb/pppppppp/8/8/8/8/PPPPPPPP/NRKQBNRB w - - 0 1",
    "nbrkqnbr/pppppppp/8/8/8/8/PPPPPPPP/NBRKQNBR w - - 0 1",
    "nrkbqnbr/pppppppp/8/8/8/8/PPPPPPPP/NRKBQNBR w - - 0 1",
    "nrkqnbbr/pppppppp/8/8/8/8/PPPPPPPP/NRKQNBBR w - - 0 1",
    "nrkqnrbb/pppppppp/8/8/8/8/PPPPPPPP/NRKQNRBB w - - 0 1",
    "bbnrknqr/pppppppp/8/8/8/8/PPPPPPPP/BBNRKNQR w - - 0 1",
    "bnrbknqr/pppppppp/8/8/8/8/PPPPPPPP/BNRBKNQR w - - 0 1",
    "bnrknbqr/pppppppp/8/8/8/8/PPPPPPPP/BNRKNBQR w - - 0 1",
    "bnrknqrb/pppppppp/8/8/8/8/PPPPPPPP/BNRKNQRB w - - 0 1",
    "nbbrknqr/pppppppp/8/8/8/8/PPPPPPPP/NBBRKNQR w - - 0 1",
    "nrbbknqr/pppppppp/8/8/8/8/PPPPPPPP/NRBBKNQR w - - 0 1",
    "nrbknbqr/pppppppp/8/8/8/8/PPPPPPPP/NRBKNBQR w - - 0 1",
    "nrbknqrb/pppppppp/8/8/8/8/PPPPPPPP/NRBKNQRB w - - 0 1",
    "nbrkbnqr/pppppppp/8/8/8/8/PPPPPPPP/NBRKBNQR w - - 0 1",
    "nrkbbnqr/pppppppp/8/8/8/8/PPPPPPPP/NRKBBNQR w - - 0 1",
    "nrknbbqr/pppppppp/8/8/8/8/PPPPPPPP/NRKNBBQR w - - 0 1",
    "nrknbqrb/pppppppp/8/8/8/8/PPPPPPPP/NRKNBQRB w - - 0 1",
    "nbrknqbr/pppppppp/8/8/8/8/PPPPPPPP/NBRKNQBR w - - 0 1",
    "nrkbnqbr/pppppppp/8/8/8/8/PPPPPPPP/NRKBNQBR w - - 0 1",
    "nrknqbbr/pppppppp/8/8/8/8/PPPPPPPP/NRKNQBBR w - - 0 1",
    "nrknqrbb/pppppppp/8/8/8/8/PPPPPPPP/NRKNQRBB w - - 0 1",
    "bbnrknrq/pppppppp/8/8/8/8/PPPPPPPP/BBNRKNRQ w - - 0 1",
    "bnrbknrq/pppppppp/8/8/8/8/PPPPPPPP/BNRBKNRQ w - - 0 1",
    "bnrknbrq/pppppppp/8/8/8/8/PPPPPPPP/BNRKNBRQ w - - 0 1",
    "bnrknrqb/pppppppp/8/8/8/8/PPPPPPPP/BNRKNRQB w - - 0 1",
    "nbbrknrq/pppppppp/8/8/8/8/PPPPPPPP/NBBRKNRQ w - - 0 1",
    "nrbbknrq/pppppppp/8/8/8/8/PPPPPPPP/NRBBKNRQ w - - 0 1",
    "nrbknbrq/pppppppp/8/8/8/8/PPPPPPPP/NRBKNBRQ w - - 0 1",
    "nrbknrqb/pppppppp/8/8/8/8/PPPPPPPP/NRBKNRQB w - - 0 1",
    "nbrkbnrq/pppppppp/8/8/8/8/PPPPPPPP/NBRKBNRQ w - - 0 1",
    "nrkbbnrq/pppppppp/8/8/8/8/PPPPPPPP/NRKBBNRQ w - - 0 1",
    "nrknbbrq/pppppppp/8/8/8/8/PPPPPPPP/NRKNBBRQ w - - 0 1",
    "nrknbrqb/pppppppp/8/8/8/8/PPPPPPPP/NRKNBRQB w - - 0 1",
    "nbrknrbq/pppppppp/8/8/8/8/PPPPPPPP/NBRKNRBQ w - - 0 1",
    "nrkbnrbq/pppppppp/8/8/8/8/PPPPPPPP/NRKBNRBQ w - - 0 1",
    "nrknrbbq/pppppppp/8/8/8/8/PPPPPPPP/NRKNRBBQ w - - 0 1",
    "nrknrqbb/pppppppp/8/8/8/8/PPPPPPPP/NRKNRQBB w - - 0 1",
    "bbqnrkrn/pppppppp/8/8/8/8/PPPPPPPP/BBQNRKRN w - - 0 1",
    "bqnbrkrn/pppppppp/8/8/8/8/PPPPPPPP/BQNBRKRN w - - 0 1",
    "bqnrkbrn/pppppppp/8/8/8/8/PPPPPPPP/BQNRKBRN w - - 0 1",
    "bqnrkrnb/pppppppp/8/8/8/8/PPPPPPPP/BQNRKRNB w - - 0 1",
    "qbbnrkrn/pppppppp/8/8/8/8/PPPPPPPP/QBBNRKRN w - - 0 1",
    "qnbbrkrn/pppppppp/8/8/8/8/PPPPPPPP/QNBBRKRN w - - 0 1",
    "qnbrkbrn/pppppppp/8/8/8/8/PPPPPPPP/QNBRKBRN w - - 0 1",
    "qnbrkrnb/pppppppp/8/8/8/8/PPPPPPPP/QNBRKRNB w - - 0 1",
    "qbnrbkrn/pppppppp/8/8/8/8/PPPPPPPP/QBNRBKRN w - - 0 1",
    "qnrbbkrn/pppppppp/8/8/8/8/PPPPPPPP/QNRBBKRN w - - 0 1",
    "qnrkbbrn/pppppppp/8/8/8/8/PPPPPPPP/QNRKBBRN w - - 0 1",
    "qnrkbrnb/pppppppp/8/8/8/8/PPPPPPPP/QNRKBRNB w - - 0 1",
    "qbnrkrbn/pppppppp/8/8/8/8/PPPPPPPP/QBNRKRBN w - - 0 1",
    "qnrbkrbn/pppppppp/8/8/8/8/PPPPPPPP/QNRBKRBN w - - 0 1",
    "qnrkrbbn/pppppppp/8/8/8/8/PPPPPPPP/QNRKRBBN w - - 0 1",
    "qnrkrnbb/pppppppp/8/8/8/8/PPPPPPPP/QNRKRNBB w - - 0 1",
    "bbnqrkrn/pppppppp/8/8/8/8/PPPPPPPP/BBNQRKRN w - - 0 1",
    "bnqbrkrn/pppppppp/8/8/8/8/PPPPPPPP/BNQBRKRN w - - 0 1",
    "bnqrkbrn/pppppppp/8/8/8/8/PPPPPPPP/BNQRKBRN w - - 0 1",
    "bnqrkrnb/pppppppp/8/8/8/8/PPPPPPPP/BNQRKRNB w - - 0 1",
    "nbbqrkrn/pppppppp/8/8/8/8/PPPPPPPP/NBBQRKRN w - - 0 1",
    "nqbbrkrn/pppppppp/8/8/8/8/PPPPPPPP/NQBBRKRN w - - 0 1",
    "nqbrkbrn/pppppppp/8/8/8/8/PPPPPPPP/NQBRKBRN w - - 0 1",
    "nqbrkrnb/pppppppp/8/8/8/8/PPPPPPPP/NQBRKRNB w - - 0 1",
    "nbqrbkrn/pppppppp/8/8/8/8/PPPPPPPP/NBQRBKRN w - - 0 1",
    "nqrbbkrn/pppppppp/8/8/8/8/PPPPPPPP/NQRBBKRN w - - 0 1",
    "nqrkbbrn/pppppppp/8/8/8/8/PPPPPPPP/NQRKBBRN w - - 0 1",
    "nqrkbrnb/pppppppp/8/8/8/8/PPPPPPPP/NQRKBRNB w - - 0 1",
    "nbqrkrbn/pppppppp/8/8/8/8/PPPPPPPP/NBQRKRBN w - - 0 1",
    "nqrbkrbn/pppppppp/8/8/8/8/PPPPPPPP/NQRBKRBN w - - 0 1",
    "nqrkrbbn/pppppppp/8/8/8/8/PPPPPPPP/NQRKRBBN w - - 0 1",
    "nqrkrnbb/pppppppp/8/8/8/8/PPPPPPPP/NQRKRNBB w - - 0 1",
    "bbnrqkrn/pppppppp/8/8/8/8/PPPPPPPP/BBNRQKRN w - - 0 1",
    "bnrbqkrn/pppppppp/8/8/8/8/PPPPPPPP/BNRBQKRN w - - 0 1",
    "bnrqkbrn/pppppppp/8/8/8/8/PPPPPPPP/BNRQKBRN w - - 0 1",
    "bnrqkrnb/pppppppp/8/8/8/8/PPPPPPPP/BNRQKRNB w - - 0 1",
    "nbbrqkrn/pppppppp/8/8/8/8/PPPPPPPP/NBBRQKRN w - - 0 1",
    "nrbbqkrn/pppppppp/8/8/8/8/PPPPPPPP/NRBBQKRN w - - 0 1",
    "nrbqkbrn/pppppppp/8/8/8/8/PPPPPPPP/NRBQKBRN w - - 0 1",
    "nrbqkrnb/pppppppp/8/8/8/8/PPPPPPPP/NRBQKRNB w - - 0 1",
    "nbrqbkrn/pppppppp/8/8/8/8/PPPPPPPP/NBRQBKRN w - - 0 1",
    "nrqbbkrn/pppppppp/8/8/8/8/PPPPPPPP/NRQBBKRN w - - 0 1",
    "nrqkbbrn/pppppppp/8/8/8/8/PPPPPPPP/NRQKBBRN w - - 0 1",
    "nrqkbrnb/pppppppp/8/8/8/8/PPPPPPPP/NRQKBRNB w - - 0 1",
    "nbrqkrbn/pppppppp/8/8/8/8/PPPPPPPP/NBRQKRBN w - - 0 1",
    "nrqbkrbn/pppppppp/8/8/8/8/PPPPPPPP/NRQBKRBN w - - 0 1",
    "nrqkrbbn/pppppppp/8/8/8/8/PPPPPPPP/NRQKRBBN w - - 0 1",
    "nrqkrnbb/pppppppp/8/8/8/8/PPPPPPPP/NRQKRNBB w - - 0 1",
    "bbnrkqrn/pppppppp/8/8/8/8/PPPPPPPP/BBNRKQRN w - - 0 1",
    "bnrbkqrn/pppppppp/8/8/8/8/PPPPPPPP/BNRBKQRN w - - 0 1",
    "bnrkqbrn/pppppppp/8/8/8/8/PPPPPPPP/BNRKQBRN w - - 0 1",
    "bnrkqrnb/pppppppp/8/8/8/8/PPPPPPPP/BNRKQRNB w - - 0 1",
    "nbbrkqrn/pppppppp/8/8/8/8/PPPPPPPP/NBBRKQRN w - - 0 1",
    "nrbbkqrn/pppppppp/8/8/8/8/PPPPPPPP/NRBBKQRN w - - 0 1",
    "nrbkqbrn/pppppppp/8/8/8/8/PPPPPPPP/NRBKQBRN w - - 0 1",
    "nrbkqrnb/pppppppp/8/8/8/8/PPPPPPPP/NRBKQRNB w - - 0 1",
    "nbrkbqrn/pppppppp/8/8/8/8/PPPPPPPP/NBRKBQRN w - - 0 1",
    "nrkbbqrn/pppppppp/8/8/8/8/PPPPPPPP/NRKBBQRN w - - 0 1",
    "nrkqbbrn/pppppppp/8/8/8/8/PPPPPPPP/NRKQBBRN w - - 0 1",
    "nrkqbrnb/pppppppp/8/8/8/8/PPPPPPPP/NRKQBRNB w - - 0 1",
    "nbrkqrbn/pppppppp/8/8/8/8/PPPPPPPP/NBRKQRBN w - - 0 1",
    "nrkbqrbn/pppppppp/8/8/8/8/PPPPPPPP/NRKBQRBN w - - 0 1",
    "nrkqrbbn/pppppppp/8/8/8/8/PPPPPPPP/NRKQRBBN w - - 0 1",
    "nrkqrnbb/pppppppp/8/8/8/8/PPPPPPPP/NRKQRNBB w - - 0 1",
    "bbnrkrqn/pppppppp/8/8/8/8/PPPPPPPP/BBNRKRQN w - - 0 1",
    "bnrbkrqn/pppppppp/8/8/8/8/PPPPPPPP/BNRBKRQN w - - 0 1",
    "bnrkrbqn/pppppppp/8/8/8/8/PPPPPPPP/BNRKRBQN w - - 0 1",
    "bnrkrqnb/pppppppp/8/8/8/8/PPPPPPPP/BNRKRQNB w - - 0 1",
    "nbbrkrqn/pppppppp/8/8/8/8/PPPPPPPP/NBBRKRQN w - - 0 1",
    "nrbbkrqn/pppppppp/8/8/8/8/PPPPPPPP/NRBBKRQN w - - 0 1",
    "nrbkrbqn/pppppppp/8/8/8/8/PPPPPPPP/NRBKRBQN w - - 0 1",
    "nrbkrqnb/pppppppp/8/8/8/8/PPPPPPPP/NRBKRQNB w - - 0 1",
    "nbrkbrqn/pppppppp/8/8/8/8/PPPPPPPP/NBRKBRQN w - - 0 1",
    "nrkbbrqn/pppppppp/8/8/8/8/PPPPPPPP/NRKBBRQN w - - 0 1",
    "nrkrbbqn/pppppppp/8/8/8/8/PPPPPPPP/NRKRBBQN w - - 0 1",
    "nrkrbqnb/pppppppp/8/8/8/8/PPPPPPPP/NRKRBQNB w - - 0 1",
    "nbrkrqbn/pppppppp/8/8/8/8/PPPPPPPP/NBRKRQBN w - - 0 1",
    "nrkbrqbn/pppppppp/8/8/8/8/PPPPPPPP/NRKBRQBN w - - 0 1",
    "nrkrqbbn/pppppppp/8/8/8/8/PPPPPPPP/NRKRQBBN w - - 0 1",
    "nrkrqnbb/pppppppp/8/8/8/8/PPPPPPPP/NRKRQNBB w - - 0 1",
    "bbnrkrnq/pppppppp/8/8/8/8/PPPPPPPP/BBNRKRNQ w - - 0 1",
    "bnrbkrnq/pppppppp/8/8/8/8/PPPPPPPP/BNRBKRNQ w - - 0 1",
    "bnrkrbnq/pppppppp/8/8/8/8/PPPPPPPP/BNRKRBNQ w - - 0 1",
    "bnrkrnqb/pppppppp/8/8/8/8/PPPPPPPP/BNRKRNQB w - - 0 1",
    "nbbrkrnq/pppppppp/8/8/8/8/PPPPPPPP/NBBRKRNQ w - - 0 1",
    "nrbbkrnq/pppppppp/8/8/8/8/PPPPPPPP/NRBBKRNQ w - - 0 1",
    "nrbkrbnq/pppppppp/8/8/8/8/PPPPPPPP/NRBKRBNQ w - - 0 1",
    "nrbkrnqb/pppppppp/8/8/8/8/PPPPPPPP/NRBKRNQB w - - 0 1",
    "nbrkbrnq/pppppppp/8/8/8/8/PPPPPPPP/NBRKBRNQ w - - 0 1",
    "nrkbbrnq/pppppppp/8/8/8/8/PPPPPPPP/NRKBBRNQ w - - 0 1",
    "nrkrbbnq/pppppppp/8/8/8/8/PPPPPPPP/NRKRBBNQ w - - 0 1",
    "nrkrbnqb/pppppppp/8/8/8/8/PPPPPPPP/NRKRBNQB w - - 0 1",
    "nbrkrnbq/pppppppp/8/8/8/8/PPPPPPPP/NBRKRNBQ w - - 0 1",
    "nrkbrnbq/pppppppp/8/8/8/8/PPPPPPPP/NRKBRNBQ w - - 0 1",
    "nrkrnbbq/pppppppp/8/8/8/8/PPPPPPPP/NRKRNBBQ w - - 0 1",
    "nrkrnqbb/pppppppp/8/8/8/8/PPPPPPPP/NRKRNQBB w - - 0 1",
    "bbqrnnkr/pppppppp/8/8/8/8/PPPPPPPP/BBQRNNKR w - - 0 1",
    "bqrbnnkr/pppppppp/8/8/8/8/PPPPPPPP/BQRBNNKR w - - 0 1",
    "bqrnnbkr/pppppppp/8/8/8/8/PPPPPPPP/BQRNNBKR w - - 0 1",
    "bqrnnkrb/pppppppp/8/8/8/8/PPPPPPPP/BQRNNKRB w - - 0 1",
    "qbbrnnkr/pppppppp/8/8/8/8/PPPPPPPP/QBBRNNKR w - - 0 1",
    "qrbbnnkr/pppppppp/8/8/8/8/PPPPPPPP/QRBBNNKR w - - 0 1",
    "qrbnnbkr/pppppppp/8/8/8/8/PPPPPPPP/QRBNNBKR w - - 0 1",
    "qrbnnkrb/pppppppp/8/8/8/8/PPPPPPPP/QRBNNKRB w - - 0 1",
    "qbrnbnkr/pppppppp/8/8/8/8/PPPPPPPP/QBRNBNKR w - - 0 1",
    "qrnbbnkr/pppppppp/8/8/8/8/PPPPPPPP/QRNBBNKR w - - 0 1",
    "qrnnbbkr/pppppppp/8/8/8/8/PPPPPPPP/QRNNBBKR w - - 0 1",
    "qrnnbkrb/pppppppp/8/8/8/8/PPPPPPPP/QRNNBKRB w - - 0 1",
    "qbrnnkbr/pppppppp/8/8/8/8/PPPPPPPP/QBRNNKBR w - - 0 1",
    "qrnbnkbr/pppppppp/8/8/8/8/PPPPPPPP/QRNBNKBR w - - 0 1",
    "qrnnkbbr/pppppppp/8/8/8/8/PPPPPPPP/QRNNKBBR w - - 0 1",
    "qrnnkrbb/pppppppp/8/8/8/8/PPPPPPPP/QRNNKRBB w - - 0 1",
    "bbrqnnkr/pppppppp/8/8/8/8/PPPPPPPP/BBRQNNKR w - - 0 1",
    "brqbnnkr/pppppppp/8/8/8/8/PPPPPPPP/BRQBNNKR w - - 0 1",
    "brqnnbkr/pppppppp/8/8/8/8/PPPPPPPP/BRQNNBKR w - - 0 1",
    "brqnnkrb/pppppppp/8/8/8/8/PPPPPPPP/BRQNNKRB w - - 0 1",
    "rbbqnnkr/pppppppp/8/8/8/8/PPPPPPPP/RBBQNNKR w - - 0 1",
    "rqbbnnkr/pppppppp/8/8/8/8/PPPPPPPP/RQBBNNKR w - - 0 1",
    "rqbnnbkr/pppppppp/8/8/8/8/PPPPPPPP/RQBNNBKR w - - 0 1",
    "rqbnnkrb/pppppppp/8/8/8/8/PPPPPPPP/RQBNNKRB w - - 0 1",
    "rbqnbnkr/pppppppp/8/8/8/8/PPPPPPPP/RBQNBNKR w - - 0 1",
    "rqnbbnkr/pppppppp/8/8/8/8/PPPPPPPP/RQNBBNKR w - - 0 1",
    "rqnnbbkr/pppppppp/8/8/8/8/PPPPPPPP/RQNNBBKR w - - 0 1",
    "rqnnbkrb/pppppppp/8/8/8/8/PPPPPPPP/RQNNBKRB w - - 0 1",
    "rbqnnkbr/pppppppp/8/8/8/8/PPPPPPPP/RBQNNKBR w - - 0 1",
    "rqnbnkbr/pppppppp/8/8/8/8/PPPPPPPP/RQNBNKBR w - - 0 1",
    "rqnnkbbr/pppppppp/8/8/8/8/PPPPPPPP/RQNNKBBR w - - 0 1",
    "rqnnkrbb/pppppppp/8/8/8/8/PPPPPPPP/RQNNKRBB w - - 0 1",
    "bbrnqnkr/pppppppp/8/8/8/8/PPPPPPPP/BBRNQNKR w - - 0 1",
    "brnbqnkr/pppppppp/8/8/8/8/PPPPPPPP/BRNBQNKR w - - 0 1",
    "brnqnbkr/pppppppp/8/8/8/8/PPPPPPPP/BRNQNBKR w - - 0 1",
    "brnqnkrb/pppppppp/8/8/8/8/PPPPPPPP/BRNQNKRB w - - 0 1",
    "rbbnqnkr/pppppppp/8/8/8/8/PPPPPPPP/RBBNQNKR w - - 0 1",
    "rnbbqnkr/pppppppp/8/8/8/8/PPPPPPPP/RNBBQNKR w - - 0 1",
    "rnbqnbkr/pppppppp/8/8/8/8/PPPPPPPP/RNBQNBKR w - - 0 1",
    "rnbqnkrb/pppppppp/8/8/8/8/PPPPPPPP/RNBQNKRB w - - 0 1",
    "rbnqbnkr/pppppppp/8/8/8/8/PPPPPPPP/RBNQBNKR w - - 0 1",
    "rnqbbnkr/pppppppp/8/8/8/8/PPPPPPPP/RNQBBNKR w - - 0 1",
    "rnqnbbkr/pppppppp/8/8/8/8/PPPPPPPP/RNQNBBKR w - - 0 1",
    "rnqnbkrb/pppppppp/8/8/8/8/PPPPPPPP/RNQNBKRB w - - 0 1",
    "rbnqnkbr/pppppppp/8/8/8/8/PPPPPPPP/RBNQNKBR w - - 0 1",
    "rnqbnkbr/pppppppp/8/8/8/8/PPPPPPPP/RNQBNKBR w - - 0 1",
    "rnqnkbbr/pppppppp/8/8/8/8/PPPPPPPP/RNQNKBBR w - - 0 1",
    "rnqnkrbb/pppppppp/8/8/8/8/PPPPPPPP/RNQNKRBB w - - 0 1",
    "bbrnnqkr/pppppppp/8/8/8/8/PPPPPPPP/BBRNNQKR w - - 0 1",
    "brnbnqkr/pppppppp/8/8/8/8/PPPPPPPP/BRNBNQKR w - - 0 1",
    "brnnqbkr/pppppppp/8/8/8/8/PPPPPPPP/BRNNQBKR w - - 0 1",
    "brnnqkrb/pppppppp/8/8/8/8/PPPPPPPP/BRNNQKRB w - - 0 1",
    "rbbnnqkr/pppppppp/8/8/8/8/PPPPPPPP/RBBNNQKR w - - 0 1",
    "rnbbnqkr/pppppppp/8/8/8/8/PPPPPPPP/RNBBNQKR w - - 0 1",
    "rnbnqbkr/pppppppp/8/8/8/8/PPPPPPPP/RNBNQBKR w - - 0 1",
    "rnbnqkrb/pppppppp/8/8/8/8/PPPPPPPP/RNBNQKRB w - - 0 1",
    "rbnnbqkr/pppppppp/8/8/8/8/PPPPPPPP/RBNNBQKR w - - 0 1",
    "rnnbbqkr/pppppppp/8/8/8/8/PPPPPPPP/RNNBBQKR w - - 0 1",
    "rnnqbbkr/pppppppp/8/8/8/8/PPPPPPPP/RNNQBBKR w - - 0 1",
    "rnnqbkrb/pppppppp/8/8/8/8/PPPPPPPP/RNNQBKRB w - - 0 1",
    "rbnnqkbr/pppppppp/8/8/8/8/PPPPPPPP/RBNNQKBR w - - 0 1",
    "rnnbqkbr/pppppppp/8/8/8/8/PPPPPPPP/RNNBQKBR w - - 0 1",
    "rnnqkbbr/pppppppp/8/8/8/8/PPPPPPPP/RNNQKBBR w - - 0 1",
    "rnnqkrbb/pppppppp/8/8/8/8/PPPPPPPP/RNNQKRBB w - - 0 1",
    "bbrnnkqr/pppppppp/8/8/8/8/PPPPPPPP/BBRNNKQR w - - 0 1",
    "brnbnkqr/pppppppp/8/8/8/8/PPPPPPPP/BRNBNKQR w - - 0 1",
    "brnnkbqr/pppppppp/8/8/8/8/PPPPPPPP/BRNNKBQR w - - 0 1",
    "brnnkqrb/pppppppp/8/8/8/8/PPPPPPPP/BRNNKQRB w - - 0 1",
    "rbbnnkqr/pppppppp/8/8/8/8/PPPPPPPP/RBBNNKQR w - - 0 1",
    "rnbbnkqr/pppppppp/8/8/8/8/PPPPPPPP/RNBBNKQR w - - 0 1",
    "rnbnkbqr/pppppppp/8/8/8/8/PPPPPPPP/RNBNKBQR w - - 0 1",
    "rnbnkqrb/pppppppp/8/8/8/8/PPPPPPPP/RNBNKQRB w - - 0 1",
    "rbnnbkqr/pppppppp/8/8/8/8/PPPPPPPP/RBNNBKQR w - - 0 1",
    "rnnbbkqr/pppppppp/8/8/8/8/PPPPPPPP/RNNBBKQR w - - 0 1",
    "rnnkbbqr/pppppppp/8/8/8/8/PPPPPPPP/RNNKBBQR w - - 0 1",
    "rnnkbqrb/pppppppp/8/8/8/8/PPPPPPPP/RNNKBQRB w - - 0 1",
    "rbnnkqbr/pppppppp/8/8/8/8/PPPPPPPP/RBNNKQBR w - - 0 1",
    "rnnbkqbr/pppppppp/8/8/8/8/PPPPPPPP/RNNBKQBR w - - 0 1",
    "rnnkqbbr/pppppppp/8/8/8/8/PPPPPPPP/RNNKQBBR w - - 0 1",
    "rnnkqrbb/pppppppp/8/8/8/8/PPPPPPPP/RNNKQRBB w - - 0 1",
    "bbrnnkrq/pppppppp/8/8/8/8/PPPPPPPP/BBRNNKRQ w - - 0 1",
    "brnbnkrq/pppppppp/8/8/8/8/PPPPPPPP/BRNBNKRQ w - - 0 1",
    "brnnkbrq/pppppppp/8/8/8/8/PPPPPPPP/BRNNKBRQ w - - 0 1",
    "brnnkrqb/pppppppp/8/8/8/8/PPPPPPPP/BRNNKRQB w - - 0 1",
    "rbbnnkrq/pppppppp/8/8/8/8/PPPPPPPP/RBBNNKRQ w - - 0 1",
    "rnbbnkrq/pppppppp/8/8/8/8/PPPPPPPP/RNBBNKRQ w - - 0 1",
    "rnbnkbrq/pppppppp/8/8/8/8/PPPPPPPP/RNBNKBRQ w - - 0 1",
    "rnbnkrqb/pppppppp/8/8/8/8/PPPPPPPP/RNBNKRQB w - - 0 1",
    "rbnnbkrq/pppppppp/8/8/8/8/PPPPPPPP/RBNNBKRQ w - - 0 1",
    "rnnbbkrq/pppppppp/8/8/8/8/PPPPPPPP/RNNBBKRQ w - - 0 1",
    "rnnkbbrq/pppppppp/8/8/8/8/PPPPPPPP/RNNKBBRQ w - - 0 1",
    "rnnkbrqb/pppppppp/8/8/8/8/PPPPPPPP/RNNKBRQB w - - 0 1",
    "rbnnkrbq/pppppppp/8/8/8/8/PPPPPPPP/RBNNKRBQ w - - 0 1",
    "rnnbkrbq/pppppppp/8/8/8/8/PPPPPPPP/RNNBKRBQ w - - 0 1",
    "rnnkrbbq/pppppppp/8/8/8/8/PPPPPPPP/RNNKRBBQ w - - 0 1",
    "rnnkrqbb/pppppppp/8/8/8/8/PPPPPPPP/RNNKRQBB w - - 0 1",
    "bbqrnknr/pppppppp/8/8/8/8/PPPPPPPP/BBQRNKNR w - - 0 1",
    "bqrbnknr/pppppppp/8/8/8/8/PPPPPPPP/BQRBNKNR w - - 0 1",
    "bqrnkbnr/pppppppp/8/8/8/8/PPPPPPPP/BQRNKBNR w - - 0 1",
    "bqrnknrb/pppppppp/8/8/8/8/PPPPPPPP/BQRNKNRB w - - 0 1",
    "qbbrnknr/pppppppp/8/8/8/8/PPPPPPPP/QBBRNKNR w - - 0 1",
    "qrbbnknr/pppppppp/8/8/8/8/PPPPPPPP/QRBBNKNR w - - 0 1",
    "qrbnkbnr/pppppppp/8/8/8/8/PPPPPPPP/QRBNKBNR w - - 0 1",
    "qrbnknrb/pppppppp/8/8/8/8/PPPPPPPP/QRBNKNRB w - - 0 1",
    "qbrnbknr/pppppppp/8/8/8/8/PPPPPPPP/QBRNBKNR w - - 0 1",
    "qrnbbknr/pppppppp/8/8/8/8/PPPPPPPP/QRNBBKNR w - - 0 1",
    "qrnkbbnr/pppppppp/8/8/8/8/PPPPPPPP/QRNKBBNR w - - 0 1",
    "qrnkbnrb/pppppppp/8/8/8/8/PPPPPPPP/QRNKBNRB w - - 0 1",
    "qbrnknbr/pppppppp/8/8/8/8/PPPPPPPP/QBRNKNBR w - - 0 1",
    "qrnbknbr/pppppppp/8/8/8/8/PPPPPPPP/QRNBKNBR w - - 0 1",
    "qrnknbbr/pppppppp/8/8/8/8/PPPPPPPP/QRNKNBBR w - - 0 1",
    "qrnknrbb/pppppppp/8/8/8/8/PPPPPPPP/QRNKNRBB w - - 0 1",
    "bbrqnknr/pppppppp/8/8/8/8/PPPPPPPP/BBRQNKNR w - - 0 1",
    "brqbnknr/pppppppp/8/8/8/8/PPPPPPPP/BRQBNKNR w - - 0 1",
    "brqnkbnr/pppppppp/8/8/8/8/PPPPPPPP/BRQNKBNR w - - 0 1",
    "brqnknrb/pppppppp/8/8/8/8/PPPPPPPP/BRQNKNRB w - - 0 1",
    "rbbqnknr/pppppppp/8/8/8/8/PPPPPPPP/RBBQNKNR w - - 0 1",
    "rqbbnknr/pppppppp/8/8/8/8/PPPPPPPP/RQBBNKNR w - - 0 1",
    "rqbnkbnr/pppppppp/8/8/8/8/PPPPPPPP/RQBNKBNR w - - 0 1",
    "rqbnknrb/pppppppp/8/8/8/8/PPPPPPPP/RQBNKNRB w - - 0 1",
    "rbqnbknr/pppppppp/8/8/8/8/PPPPPPPP/RBQNBKNR w - - 0 1",
    "rqnbbknr/pppppppp/8/8/8/8/PPPPPPPP/RQNBBKNR w - - 0 1",
    "rqnkbbnr/pppppppp/8/8/8/8/PPPPPPPP/RQNKBBNR w - - 0 1",
    "rqnkbnrb/pppppppp/8/8/8/8/PPPPPPPP/RQNKBNRB w - - 0 1",
    "rbqnknbr/pppppppp/8/8/8/8/PPPPPPPP/RBQNKNBR w - - 0 1",
    "rqnbknbr/pppppppp/8/8/8/8/PPPPPPPP/RQNBKNBR w - - 0 1",
    "rqnknbbr/pppppppp/8/8/8/8/PPPPPPPP/RQNKNBBR w - - 0 1",
    "rqnknrbb/pppppppp/8/8/8/8/PPPPPPPP/RQNKNRBB w - - 0 1",
    "bbrnqknr/pppppppp/8/8/8/8/PPPPPPPP/BBRNQKNR w - - 0 1",
    "brnbqknr/pppppppp/8/8/8/8/PPPPPPPP/BRNBQKNR w - - 0 1",
    "brnqkbnr/pppppppp/8/8/8/8/PPPPPPPP/BRNQKBNR w - - 0 1",
    "brnqknrb/pppppppp/8/8/8/8/PPPPPPPP/BRNQKNRB w - - 0 1",
    "rbbnqknr/pppppppp/8/8/8/8/PPPPPPPP/RBBNQKNR w - - 0 1",
    "rnbbqknr/pppppppp/8/8/8/8/PPPPPPPP/RNBBQKNR w - - 0 1",
    "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w - - 0 1",
    "rnbqknrb/pppppppp/8/8/8/8/PPPPPPPP/RNBQKNRB w - - 0 1",
    "rbnqbknr/pppppppp/8/8/8/8/PPPPPPPP/RBNQBKNR w - - 0 1",
    "rnqbbknr/pppppppp/8/8/8/8/PPPPPPPP/RNQBBKNR w - - 0 1",
    "rnqkbbnr/pppppppp/8/8/8/8/PPPPPPPP/RNQKBBNR w - - 0 1",
    "rnqkbnrb/pppppppp/8/8/8/8/PPPPPPPP/RNQKBNRB w - - 0 1",
    "rbnqknbr/pppppppp/8/8/8/8/PPPPPPPP/RBNQKNBR w - - 0 1",
    "rnqbknbr/pppppppp/8/8/8/8/PPPPPPPP/RNQBKNBR w - - 0 1",
    "rnqknbbr/pppppppp/8/8/8/8/PPPPPPPP/RNQKNBBR w - - 0 1",
    "rnqknrbb/pppppppp/8/8/8/8/PPPPPPPP/RNQKNRBB w - - 0 1",
    "bbrnkqnr/pppppppp/8/8/8/8/PPPPPPPP/BBRNKQNR w - - 0 1",
    "brnbkqnr/pppppppp/8/8/8/8/PPPPPPPP/BRNBKQNR w - - 0 1",
    "brnkqbnr/pppppppp/8/8/8/8/PPPPPPPP/BRNKQBNR w - - 0 1",
    "brnkqnrb/pppppppp/8/8/8/8/PPPPPPPP/BRNKQNRB w - - 0 1",
    "rbbnkqnr/pppppppp/8/8/8/8/PPPPPPPP/RBBNKQNR w - - 0 1",
    "rnbbkqnr/pppppppp/8/8/8/8/PPPPPPPP/RNBBKQNR w - - 0 1",
    "rnbkqbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBKQBNR w - - 0 1",
    "rnbkqnrb/pppppppp/8/8/8/8/PPPPPPPP/RNBKQNRB w - - 0 1",
    "rbnkbqnr/pppppppp/8/8/8/8/PPPPPPPP/RBNKBQNR w - - 0 1",
    "rnkbbqnr/pppppppp/8/8/8/8/PPPPPPPP/RNKBBQNR w - - 0 1",
    "rnkqbbnr/pppppppp/8/8/8/8/PPPPPPPP/RNKQBBNR w - - 0 1",
    "rnkqbnrb/pppppppp/8/8/8/8/PPPPPPPP/RNKQBNRB w - - 0 1",
    "rbnkqnbr/pppppppp/8/8/8/8/PPPPPPPP/RBNKQNBR w - - 0 1",
    "rnkbqnbr/pppppppp/8/8/8/8/PPPPPPPP/RNKBQNBR w - - 0 1",
    "rnkqnbbr/pppppppp/8/8/8/8/PPPPPPPP/RNKQNBBR w - - 0 1",
    "rnkqnrbb/pppppppp/8/8/8/8/PPPPPPPP/RNKQNRBB w - - 0 1",
    "bbrnknqr/pppppppp/8/8/8/8/PPPPPPPP/BBRNKNQR w - - 0 1",
    "brnbknqr/pppppppp/8/8/8/8/PPPPPPPP/BRNBKNQR w - - 0 1",
    "brnknbqr/pppppppp/8/8/8/8/PPPPPPPP/BRNKNBQR w - - 0 1",
    "brnknqrb/pppppppp/8/8/8/8/PPPPPPPP/BRNKNQRB w - - 0 1",
    "rbbnknqr/pppppppp/8/8/8/8/PPPPPPPP/RBBNKNQR w - - 0 1",
    "rnbbknqr/pppppppp/8/8/8/8/PPPPPPPP/RNBBKNQR w - - 0 1",
    "rnbknbqr/pppppppp/8/8/8/8/PPPPPPPP/RNBKNBQR w - - 0 1",
    "rnbknqrb/pppppppp/8/8/8/8/PPPPPPPP/RNBKNQRB w - - 0 1",
    "rbnkbnqr/pppppppp/8/8/8/8/PPPPPPPP/RBNKBNQR w - - 0 1",
    "rnkbbnqr/pppppppp/8/8/8/8/PPPPPPPP/RNKBBNQR w - - 0 1",
    "rnknbbqr/pppppppp/8/8/8/8/PPPPPPPP/RNKNBBQR w - - 0 1",
    "rnknbqrb/pppppppp/8/8/8/8/PPPPPPPP/RNKNBQRB w - - 0 1",
    "rbnknqbr/pppppppp/8/8/8/8/PPPPPPPP/RBNKNQBR w - - 0 1",
    "rnkbnqbr/pppppppp/8/8/8/8/PPPPPPPP/RNKBNQBR w - - 0 1",
    "rnknqbbr/pppppppp/8/8/8/8/PPPPPPPP/RNKNQBBR w - - 0 1",
    "rnknqrbb/pppppppp/8/8/8/8/PPPPPPPP/RNKNQRBB w - - 0 1",
    "bbrnknrq/pppppppp/8/8/8/8/PPPPPPPP/BBRNKNRQ w - - 0 1",
    "brnbknrq/pppppppp/8/8/8/8/PPPPPPPP/BRNBKNRQ w - - 0 1",
    "brnknbrq/pppppppp/8/8/8/8/PPPPPPPP/BRNKNBRQ w - - 0 1",
    "brnknrqb/pppppppp/8/8/8/8/PPPPPPPP/BRNKNRQB w - - 0 1",
    "rbbnknrq/pppppppp/8/8/8/8/PPPPPPPP/RBBNKNRQ w - - 0 1",
    "rnbbknrq/pppppppp/8/8/8/8/PPPPPPPP/RNBBKNRQ w - - 0 1",
    "rnbknbrq/pppppppp/8/8/8/8/PPPPPPPP/RNBKNBRQ w - - 0 1",
    "rnbknrqb/pppppppp/8/8/8/8/PPPPPPPP/RNBKNRQB w - - 0 1",
    "rbnkbnrq/pppppppp/8/8/8/8/PPPPPPPP/RBNKBNRQ w - - 0 1",
    "rnkbbnrq/pppppppp/8/8/8/8/PPPPPPPP/RNKBBNRQ w - - 0 1",
    "rnknbbrq/pppppppp/8/8/8/8/PPPPPPPP/RNKNBBRQ w - - 0 1",
    "rnknbrqb/pppppppp/8/8/8/8/PPPPPPPP/RNKNBRQB w - - 0 1",
    "rbnknrbq/pppppppp/8/8/8/8/PPPPPPPP/RBNKNRBQ w - - 0 1",
    "rnkbnrbq/pppppppp/8/8/8/8/PPPPPPPP/RNKBNRBQ w - - 0 1",
    "rnknrbbq/pppppppp/8/8/8/8/PPPPPPPP/RNKNRBBQ w - - 0 1",
    "rnknrqbb/pppppppp/8/8/8/8/PPPPPPPP/RNKNRQBB w - - 0 1",
    "bbqrnkrn/pppppppp/8/8/8/8/PPPPPPPP/BBQRNKRN w - - 0 1",
    "bqrbnkrn/pppppppp/8/8/8/8/PPPPPPPP/BQRBNKRN w - - 0 1",
    "bqrnkbrn/pppppppp/8/8/8/8/PPPPPPPP/BQRNKBRN w - - 0 1",
    "bqrnkrnb/pppppppp/8/8/8/8/PPPPPPPP/BQRNKRNB w - - 0 1",
    "qbbrnkrn/pppppppp/8/8/8/8/PPPPPPPP/QBBRNKRN w - - 0 1",
    "qrbbnkrn/pppppppp/8/8/8/8/PPPPPPPP/QRBBNKRN w - - 0 1",
    "qrbnkbrn/pppppppp/8/8/8/8/PPPPPPPP/QRBNKBRN w - - 0 1",
    "qrbnkrnb/pppppppp/8/8/8/8/PPPPPPPP/QRBNKRNB w - - 0 1",
    "qbrnbkrn/pppppppp/8/8/8/8/PPPPPPPP/QBRNBKRN w - - 0 1",
    "qrnbbkrn/pppppppp/8/8/8/8/PPPPPPPP/QRNBBKRN w - - 0 1",
    "qrnkbbrn/pppppppp/8/8/8/8/PPPPPPPP/QRNKBBRN w - - 0 1",
    "qrnkbrnb/pppppppp/8/8/8/8/PPPPPPPP/QRNKBRNB w - - 0 1",
    "qbrnkrbn/pppppppp/8/8/8/8/PPPPPPPP/QBRNKRBN w - - 0 1",
    "qrnbkrbn/pppppppp/8/8/8/8/PPPPPPPP/QRNBKRBN w - - 0 1",
    "qrnkrbbn/pppppppp/8/8/8/8/PPPPPPPP/QRNKRBBN w - - 0 1",
    "qrnkrnbb/pppppppp/8/8/8/8/PPPPPPPP/QRNKRNBB w - - 0 1",
    "bbrqnkrn/pppppppp/8/8/8/8/PPPPPPPP/BBRQNKRN w - - 0 1",
    "brqbnkrn/pppppppp/8/8/8/8/PPPPPPPP/BRQBNKRN w - - 0 1",
    "brqnkbrn/pppppppp/8/8/8/8/PPPPPPPP/BRQNKBRN w - - 0 1",
    "brqnkrnb/pppppppp/8/8/8/8/PPPPPPPP/BRQNKRNB w - - 0 1",
    "rbbqnkrn/pppppppp/8/8/8/8/PPPPPPPP/RBBQNKRN w - - 0 1",
    "rqbbnkrn/pppppppp/8/8/8/8/PPPPPPPP/RQBBNKRN w - - 0 1",
    "rqbnkbrn/pppppppp/8/8/8/8/PPPPPPPP/RQBNKBRN w - - 0 1",
    "rqbnkrnb/pppppppp/8/8/8/8/PPPPPPPP/RQBNKRNB w - - 0 1",
    "rbqnbkrn/pppppppp/8/8/8/8/PPPPPPPP/RBQNBKRN w - - 0 1",
    "rqnbbkrn/pppppppp/8/8/8/8/PPPPPPPP/RQNBBKRN w - - 0 1",
    "rqnkbbrn/pppppppp/8/8/8/8/PPPPPPPP/RQNKBBRN w - - 0 1",
    "rqnkbrnb/pppppppp/8/8/8/8/PPPPPPPP/RQNKBRNB w - - 0 1",
    "rbqnkrbn/pppppppp/8/8/8/8/PPPPPPPP/RBQNKRBN w - - 0 1",
    "rqnbkrbn/pppppppp/8/8/8/8/PPPPPPPP/RQNBKRBN w - - 0 1",
    "rqnkrbbn/pppppppp/8/8/8/8/PPPPPPPP/RQNKRBBN w - - 0 1",
    "rqnkrnbb/pppppppp/8/8/8/8/PPPPPPPP/RQNKRNBB w - - 0 1",
    "bbrnqkrn/pppppppp/8/8/8/8/PPPPPPPP/BBRNQKRN w - - 0 1",
    "brnbqkrn/pppppppp/8/8/8/8/PPPPPPPP/BRNBQKRN w - - 0 1",
    "brnqkbrn/pppppppp/8/8/8/8/PPPPPPPP/BRNQKBRN w - - 0 1",
    "brnqkrnb/pppppppp/8/8/8/8/PPPPPPPP/BRNQKRNB w - - 0 1",
    "rbbnqkrn/pppppppp/8/8/8/8/PPPPPPPP/RBBNQKRN w - - 0 1",
    "rnbbqkrn/pppppppp/8/8/8/8/PPPPPPPP/RNBBQKRN w - - 0 1",
    "rnbqkbrn/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBRN w - - 0 1",
    "rnbqkrnb/pppppppp/8/8/8/8/PPPPPPPP/RNBQKRNB w - - 0 1",
    "rbnqbkrn/pppppppp/8/8/8/8/PPPPPPPP/RBNQBKRN w - - 0 1",
    "rnqbbkrn/pppppppp/8/8/8/8/PPPPPPPP/RNQBBKRN w - - 0 1",
    "rnqkbbrn/pppppppp/8/8/8/8/PPPPPPPP/RNQKBBRN w - - 0 1",
    "rnqkbrnb/pppppppp/8/8/8/8/PPPPPPPP/RNQKBRNB w - - 0 1",
    "rbnqkrbn/pppppppp/8/8/8/8/PPPPPPPP/RBNQKRBN w - - 0 1",
    "rnqbkrbn/pppppppp/8/8/8/8/PPPPPPPP/RNQBKRBN w - - 0 1",
    "rnqkrbbn/pppppppp/8/8/8/8/PPPPPPPP/RNQKRBBN w - - 0 1",
    "rnqkrnbb/pppppppp/8/8/8/8/PPPPPPPP/RNQKRNBB w - - 0 1",
    "bbrnkqrn/pppppppp/8/8/8/8/PPPPPPPP/BBRNKQRN w - - 0 1",
    "brnbkqrn/pppppppp/8/8/8/8/PPPPPPPP/BRNBKQRN w - - 0 1",
    "brnkqbrn/pppppppp/8/8/8/8/PPPPPPPP/BRNKQBRN w - - 0 1",
    "brnkqrnb/pppppppp/8/8/8/8/PPPPPPPP/BRNKQRNB w - - 0 1",
    "rbbnkqrn/pppppppp/8/8/8/8/PPPPPPPP/RBBNKQRN w - - 0 1",
    "rnbbkqrn/pppppppp/8/8/8/8/PPPPPPPP/RNBBKQRN w - - 0 1",
    "rnbkqbrn/pppppppp/8/8/8/8/PPPPPPPP/RNBKQBRN w - - 0 1",
    "rnbkqrnb/pppppppp/8/8/8/8/PPPPPPPP/RNBKQRNB w - - 0 1",
    "rbnkbqrn/pppppppp/8/8/8/8/PPPPPPPP/RBNKBQRN w - - 0 1",
    "rnkbbqrn/pppppppp/8/8/8/8/PPPPPPPP/RNKBBQRN w - - 0 1",
    "rnkqbbrn/pppppppp/8/8/8/8/PPPPPPPP/RNKQBBRN w - - 0 1",
    "rnkqbrnb/pppppppp/8/8/8/8/PPPPPPPP/RNKQBRNB w - - 0 1",
    "rbnkqrbn/pppppppp/8/8/8/8/PPPPPPPP/RBNKQRBN w - - 0 1",
    "rnkbqrbn/pppppppp/8/8/8/8/PPPPPPPP/RNKBQRBN w - - 0 1",
    "rnkqrbbn/pppppppp/8/8/8/8/PPPPPPPP/RNKQRBBN w - - 0 1",
    "rnkqrnbb/pppppppp/8/8/8/8/PPPPPPPP/RNKQRNBB w - - 0 1",
    "bbrnkrqn/pppppppp/8/8/8/8/PPPPPPPP/BBRNKRQN w - - 0 1",
    "brnbkrqn/pppppppp/8/8/8/8/PPPPPPPP/BRNBKRQN w - - 0 1",
    "brnkrbqn/pppppppp/8/8/8/8/PPPPPPPP/BRNKRBQN w - - 0 1",
    "brnkrqnb/pppppppp/8/8/8/8/PPPPPPPP/BRNKRQNB w - - 0 1",
    "rbbnkrqn/pppppppp/8/8/8/8/PPPPPPPP/RBBNKRQN w - - 0 1",
    "rnbbkrqn/pppppppp/8/8/8/8/PPPPPPPP/RNBBKRQN w - - 0 1",
    "rnbkrbqn/pppppppp/8/8/8/8/PPPPPPPP/RNBKRBQN w - - 0 1",
    "rnbkrqnb/pppppppp/8/8/8/8/PPPPPPPP/RNBKRQNB w - - 0 1",
    "rbnkbrqn/pppppppp/8/8/8/8/PPPPPPPP/RBNKBRQN w - - 0 1",
    "rnkbbrqn/pppppppp/8/8/8/8/PPPPPPPP/RNKBBRQN w - - 0 1",
    "rnkrbbqn/pppppppp/8/8/8/8/PPPPPPPP/RNKRBBQN w - - 0 1",
    "rnkrbqnb/pppppppp/8/8/8/8/PPPPPPPP/RNKRBQNB w - - 0 1",
    "rbnkrqbn/pppppppp/8/8/8/8/PPPPPPPP/RBNKRQBN w - - 0 1",
    "rnkbrqbn/pppppppp/8/8/8/8/PPPPPPPP/RNKBRQBN w - - 0 1",
    "rnkrqbbn/pppppppp/8/8/8/8/PPPPPPPP/RNKRQBBN w - - 0 1",
    "rnkrqnbb/pppppppp/8/8/8/8/PPPPPPPP/RNKRQNBB w - - 0 1",
    "bbrnkrnq/pppppppp/8/8/8/8/PPPPPPPP/BBRNKRNQ w - - 0 1",
    "brnbkrnq/pppppppp/8/8/8/8/PPPPPPPP/BRNBKRNQ w - - 0 1",
    "brnkrbnq/pppppppp/8/8/8/8/PPPPPPPP/BRNKRBNQ w - - 0 1",
    "brnkrnqb/pppppppp/8/8/8/8/PPPPPPPP/BRNKRNQB w - - 0 1",
    "rbbnkrnq/pppppppp/8/8/8/8/PPPPPPPP/RBBNKRNQ w - - 0 1",
    "rnbbkrnq/pppppppp/8/8/8/8/PPPPPPPP/RNBBKRNQ w - - 0 1",
    "rnbkrbnq/pppppppp/8/8/8/8/PPPPPPPP/RNBKRBNQ w - - 0 1",
    "rnbkrnqb/pppppppp/8/8/8/8/PPPPPPPP/RNBKRNQB w - - 0 1",
    "rbnkbrnq/pppppppp/8/8/8/8/PPPPPPPP/RBNKBRNQ w - - 0 1",
    "rnkbbrnq/pppppppp/8/8/8/8/PPPPPPPP/RNKBBRNQ w - - 0 1",
    "rnkrbbnq/pppppppp/8/8/8/8/PPPPPPPP/RNKRBBNQ w - - 0 1",
    "rnkrbnqb/pppppppp/8/8/8/8/PPPPPPPP/RNKRBNQB w - - 0 1",
    "rbnkrnbq/pppppppp/8/8/8/8/PPPPPPPP/RBNKRNBQ w - - 0 1",
    "rnkbrnbq/pppppppp/8/8/8/8/PPPPPPPP/RNKBRNBQ w - - 0 1",
    "rnkrnbbq/pppppppp/8/8/8/8/PPPPPPPP/RNKRNBBQ w - - 0 1",
    "rnkrnqbb/pppppppp/8/8/8/8/PPPPPPPP/RNKRNQBB w - - 0 1",
    "bbqrknnr/pppppppp/8/8/8/8/PPPPPPPP/BBQRKNNR w - - 0 1",
    "bqrbknnr/pppppppp/8/8/8/8/PPPPPPPP/BQRBKNNR w - - 0 1",
    "bqrknbnr/pppppppp/8/8/8/8/PPPPPPPP/BQRKNBNR w - - 0 1",
    "bqrknnrb/pppppppp/8/8/8/8/PPPPPPPP/BQRKNNRB w - - 0 1",
    "qbbrknnr/pppppppp/8/8/8/8/PPPPPPPP/QBBRKNNR w - - 0 1",
    "qrbbknnr/pppppppp/8/8/8/8/PPPPPPPP/QRBBKNNR w - - 0 1",
    "qrbknbnr/pppppppp/8/8/8/8/PPPPPPPP/QRBKNBNR w - - 0 1",
    "qrbknnrb/pppppppp/8/8/8/8/PPPPPPPP/QRBKNNRB w - - 0 1",
    "qbrkbnnr/pppppppp/8/8/8/8/PPPPPPPP/QBRKBNNR w - - 0 1",
    "qrkbbnnr/pppppppp/8/8/8/8/PPPPPPPP/QRKBBNNR w - - 0 1",
    "qrknbbnr/pppppppp/8/8/8/8/PPPPPPPP/QRKNBBNR w - - 0 1",
    "qrknbnrb/pppppppp/8/8/8/8/PPPPPPPP/QRKNBNRB w - - 0 1",
    "qbrknnbr/pppppppp/8/8/8/8/PPPPPPPP/QBRKNNBR w - - 0 1",
    "qrkbnnbr/pppppppp/8/8/8/8/PPPPPPPP/QRKBNNBR w - - 0 1",
    "qrknnbbr/pppppppp/8/8/8/8/PPPPPPPP/QRKNNBBR w - - 0 1",
    "qrknnrbb/pppppppp/8/8/8/8/PPPPPPPP/QRKNNRBB w - - 0 1",
    "bbrqknnr/pppppppp/8/8/8/8/PPPPPPPP/BBRQKNNR w - - 0 1",
    "brqbknnr/pppppppp/8/8/8/8/PPPPPPPP/BRQBKNNR w - - 0 1",
    "brqknbnr/pppppppp/8/8/8/8/PPPPPPPP/BRQKNBNR w - - 0 1",
    "brqknnrb/pppppppp/8/8/8/8/PPPPPPPP/BRQKNNRB w - - 0 1",
    "rbbqknnr/pppppppp/8/8/8/8/PPPPPPPP/RBBQKNNR w - - 0 1",
    "rqbbknnr/pppppppp/8/8/8/8/PPPPPPPP/RQBBKNNR w - - 0 1",
    "rqbknbnr/pppppppp/8/8/8/8/PPPPPPPP/RQBKNBNR w - - 0 1",
    "rqbknnrb/pppppppp/8/8/8/8/PPPPPPPP/RQBKNNRB w - - 0 1",
    "rbqkbnnr/pppppppp/8/8/8/8/PPPPPPPP/RBQKBNNR w - - 0 1",
    "rqkbbnnr/pppppppp/8/8/8/8/PPPPPPPP/RQKBBNNR w - - 0 1",
    "rqknbbnr/pppppppp/8/8/8/8/PPPPPPPP/RQKNBBNR w - - 0 1",
    "rqknbnrb/pppppppp/8/8/8/8/PPPPPPPP/RQKNBNRB w - - 0 1",
    "rbqknnbr/pppppppp/8/8/8/8/PPPPPPPP/RBQKNNBR w - - 0 1",
    "rqkbnnbr/pppppppp/8/8/8/8/PPPPPPPP/RQKBNNBR w - - 0 1",
    "rqknnbbr/pppppppp/8/8/8/8/PPPPPPPP/RQKNNBBR w - - 0 1",
    "rqknnrbb/pppppppp/8/8/8/8/PPPPPPPP/RQKNNRBB w - - 0 1",
    "bbrkqnnr/pppppppp/8/8/8/8/PPPPPPPP/BBRKQNNR w - - 0 1",
    "brkbqnnr/pppppppp/8/8/8/8/PPPPPPPP/BRKBQNNR w - - 0 1",
    "brkqnbnr/pppppppp/8/8/8/8/PPPPPPPP/BRKQNBNR w - - 0 1",
    "brkqnnrb/pppppppp/8/8/8/8/PPPPPPPP/BRKQNNRB w - - 0 1",
    "rbbkqnnr/pppppppp/8/8/8/8/PPPPPPPP/RBBKQNNR w - - 0 1",
    "rkbbqnnr/pppppppp/8/8/8/8/PPPPPPPP/RKBBQNNR w - - 0 1",
    "rkbqnbnr/pppppppp/8/8/8/8/PPPPPPPP/RKBQNBNR w - - 0 1",
    "rkbqnnrb/pppppppp/8/8/8/8/PPPPPPPP/RKBQNNRB w - - 0 1",
    "rbkqbnnr/pppppppp/8/8/8/8/PPPPPPPP/RBKQBNNR w - - 0 1",
    "rkqbbnnr/pppppppp/8/8/8/8/PPPPPPPP/RKQBBNNR w - - 0 1",
    "rkqnbbnr/pppppppp/8/8/8/8/PPPPPPPP/RKQNBBNR w - - 0 1",
    "rkqnbnrb/pppppppp/8/8/8/8/PPPPPPPP/RKQNBNRB w - - 0 1",
    "rbkqnnbr/pppppppp/8/8/8/8/PPPPPPPP/RBKQNNBR w - - 0 1",
    "rkqbnnbr/pppppppp/8/8/8/8/PPPPPPPP/RKQBNNBR w - - 0 1",
    "rkqnnbbr/pppppppp/8/8/8/8/PPPPPPPP/RKQNNBBR w - - 0 1",
    "rkqnnrbb/pppppppp/8/8/8/8/PPPPPPPP/RKQNNRBB w - - 0 1",
    "bbrknqnr/pppppppp/8/8/8/8/PPPPPPPP/BBRKNQNR w - - 0 1",
    "brkbnqnr/pppppppp/8/8/8/8/PPPPPPPP/BRKBNQNR w - - 0 1",
    "brknqbnr/pppppppp/8/8/8/8/PPPPPPPP/BRKNQBNR w - - 0 1",
    "brknqnrb/pppppppp/8/8/8/8/PPPPPPPP/BRKNQNRB w - - 0 1",
    "rbbknqnr/pppppppp/8/8/8/8/PPPPPPPP/RBBKNQNR w - - 0 1",
    "rkbbnqnr/pppppppp/8/8/8/8/PPPPPPPP/RKBBNQNR w - - 0 1",
    "rkbnqbnr/pppppppp/8/8/8/8/PPPPPPPP/RKBNQBNR w - - 0 1",
    "rkbnqnrb/pppppppp/8/8/8/8/PPPPPPPP/RKBNQNRB w - - 0 1",
    "rbknbqnr/pppppppp/8/8/8/8/PPPPPPPP/RBKNBQNR w - - 0 1",
    "rknbbqnr/pppppppp/8/8/8/8/PPPPPPPP/RKNBBQNR w - - 0 1",
    "rknqbbnr/pppppppp/8/8/8/8/PPPPPPPP/RKNQBBNR w - - 0 1",
    "rknqbnrb/pppppppp/8/8/8/8/PPPPPPPP/RKNQBNRB w - - 0 1",
    "rbknqnbr/pppppppp/8/8/8/8/PPPPPPPP/RBKNQNBR w - - 0 1",
    "rknbqnbr/pppppppp/8/8/8/8/PPPPPPPP/RKNBQNBR w - - 0 1",
    "rknqnbbr/pppppppp/8/8/8/8/PPPPPPPP/RKNQNBBR w - - 0 1",
    "rknqnrbb/pppppppp/8/8/8/8/PPPPPPPP/RKNQNRBB w - - 0 1",
    "bbrknnqr/pppppppp/8/8/8/8/PPPPPPPP/BBRKNNQR w - - 0 1",
    "brkbnnqr/pppppppp/8/8/8/8/PPPPPPPP/BRKBNNQR w - - 0 1",
    "brknnbqr/pppppppp/8/8/8/8/PPPPPPPP/BRKNNBQR w - - 0 1",
    "brknnqrb/pppppppp/8/8/8/8/PPPPPPPP/BRKNNQRB w - - 0 1",
    "rbbknnqr/pppppppp/8/8/8/8/PPPPPPPP/RBBKNNQR w - - 0 1",
    "rkbbnnqr/pppppppp/8/8/8/8/PPPPPPPP/RKBBNNQR w - - 0 1",
    "rkbnnbqr/pppppppp/8/8/8/8/PPPPPPPP/RKBNNBQR w - - 0 1",
    "rkbnnqrb/pppppppp/8/8/8/8/PPPPPPPP/RKBNNQRB w - - 0 1",
    "rbknbnqr/pppppppp/8/8/8/8/PPPPPPPP/RBKNBNQR w - - 0 1",
    "rknbbnqr/pppppppp/8/8/8/8/PPPPPPPP/RKNBBNQR w - - 0 1",
    "rknnbbqr/pppppppp/8/8/8/8/PPPPPPPP/RKNNBBQR w - - 0 1",
    "rknnbqrb/pppppppp/8/8/8/8/PPPPPPPP/RKNNBQRB w - - 0 1",
    "rbknnqbr/pppppppp/8/8/8/8/PPPPPPPP/RBKNNQBR w - - 0 1",
    "rknbnqbr/pppppppp/8/8/8/8/PPPPPPPP/RKNBNQBR w - - 0 1",
    "rknnqbbr/pppppppp/8/8/8/8/PPPPPPPP/RKNNQBBR w - - 0 1",
    "rknnqrbb/pppppppp/8/8/8/8/PPPPPPPP/RKNNQRBB w - - 0 1",
    "bbrknnrq/pppppppp/8/8/8/8/PPPPPPPP/BBRKNNRQ w - - 0 1",
    "brkbnnrq/pppppppp/8/8/8/8/PPPPPPPP/BRKBNNRQ w - - 0 1",
    "brknnbrq/pppppppp/8/8/8/8/PPPPPPPP/BRKNNBRQ w - - 0 1",
    "brknnrqb/pppppppp/8/8/8/8/PPPPPPPP/BRKNNRQB w - - 0 1",
    "rbbknnrq/pppppppp/8/8/8/8/PPPPPPPP/RBBKNNRQ w - - 0 1",
    "rkbbnnrq/pppppppp/8/8/8/8/PPPPPPPP/RKBBNNRQ w - - 0 1",
    "rkbnnbrq/pppppppp/8/8/8/8/PPPPPPPP/RKBNNBRQ w - - 0 1",
    "rkbnnrqb/pppppppp/8/8/8/8/PPPPPPPP/RKBNNRQB w - - 0 1",
    "rbknbnrq/pppppppp/8/8/8/8/PPPPPPPP/RBKNBNRQ w - - 0 1",
    "rknbbnrq/pppppppp/8/8/8/8/PPPPPPPP/RKNBBNRQ w - - 0 1",
    "rknnbbrq/pppppppp/8/8/8/8/PPPPPPPP/RKNNBBRQ w - - 0 1",
    "rknnbrqb/pppppppp/8/8/8/8/PPPPPPPP/RKNNBRQB w - - 0 1",
    "rbknnrbq/pppppppp/8/8/8/8/PPPPPPPP/RBKNNRBQ w - - 0 1",
    "rknbnrbq/pppppppp/8/8/8/8/PPPPPPPP/RKNBNRBQ w - - 0 1",
    "rknnrbbq/pppppppp/8/8/8/8/PPPPPPPP/RKNNRBBQ w - - 0 1",
    "rknnrqbb/pppppppp/8/8/8/8/PPPPPPPP/RKNNRQBB w - - 0 1",
    "bbqrknrn/pppppppp/8/8/8/8/PPPPPPPP/BBQRKNRN w - - 0 1",
    "bqrbknrn/pppppppp/8/8/8/8/PPPPPPPP/BQRBKNRN w - - 0 1",
    "bqrknbrn/pppppppp/8/8/8/8/PPPPPPPP/BQRKNBRN w - - 0 1",
    "bqrknrnb/pppppppp/8/8/8/8/PPPPPPPP/BQRKNRNB w - - 0 1",
    "qbbrknrn/pppppppp/8/8/8/8/PPPPPPPP/QBBRKNRN w - - 0 1",
    "qrbbknrn/pppppppp/8/8/8/8/PPPPPPPP/QRBBKNRN w - - 0 1",
    "qrbknbrn/pppppppp/8/8/8/8/PPPPPPPP/QRBKNBRN w - - 0 1",
    "qrbknrnb/pppppppp/8/8/8/8/PPPPPPPP/QRBKNRNB w - - 0 1",
    "qbrkbnrn/pppppppp/8/8/8/8/PPPPPPPP/QBRKBNRN w - - 0 1",
    "qrkbbnrn/pppppppp/8/8/8/8/PPPPPPPP/QRKBBNRN w - - 0 1",
    "qrknbbrn/pppppppp/8/8/8/8/PPPPPPPP/QRKNBBRN w - - 0 1",
    "qrknbrnb/pppppppp/8/8/8/8/PPPPPPPP/QRKNBRNB w - - 0 1",
    "qbrknrbn/pppppppp/8/8/8/8/PPPPPPPP/QBRKNRBN w - - 0 1",
    "qrkbnrbn/pppppppp/8/8/8/8/PPPPPPPP/QRKBNRBN w - - 0 1",
    "qrknrbbn/pppppppp/8/8/8/8/PPPPPPPP/QRKNRBBN w - - 0 1",
    "qrknrnbb/pppppppp/8/8/8/8/PPPPPPPP/QRKNRNBB w - - 0 1",
    "bbrqknrn/pppppppp/8/8/8/8/PPPPPPPP/BBRQKNRN w - - 0 1",
    "brqbknrn/pppppppp/8/8/8/8/PPPPPPPP/BRQBKNRN w - - 0 1",
    "brqknbrn/pppppppp/8/8/8/8/PPPPPPPP/BRQKNBRN w - - 0 1",
    "brqknrnb/pppppppp/8/8/8/8/PPPPPPPP/BRQKNRNB w - - 0 1",
    "rbbqknrn/pppppppp/8/8/8/8/PPPPPPPP/RBBQKNRN w - - 0 1",
    "rqbbknrn/pppppppp/8/8/8/8/PPPPPPPP/RQBBKNRN w - - 0 1",
    "rqbknbrn/pppppppp/8/8/8/8/PPPPPPPP/RQBKNBRN w - - 0 1",
    "rqbknrnb/pppppppp/8/8/8/8/PPPPPPPP/RQBKNRNB w - - 0 1",
    "rbqkbnrn/pppppppp/8/8/8/8/PPPPPPPP/RBQKBNRN w - - 0 1",
    "rqkbbnrn/pppppppp/8/8/8/8/PPPPPPPP/RQKBBNRN w - - 0 1",
    "rqknbbrn/pppppppp/8/8/8/8/PPPPPPPP/RQKNBBRN w - - 0 1",
    "rqknbrnb/pppppppp/8/8/8/8/PPPPPPPP/RQKNBRNB w - - 0 1",
    "rbqknrbn/pppppppp/8/8/8/8/PPPPPPPP/RBQKNRBN w - - 0 1",
    "rqkbnrbn/pppppppp/8/8/8/8/PPPPPPPP/RQKBNRBN w - - 0 1",
    "rqknrbbn/pppppppp/8/8/8/8/PPPPPPPP/RQKNRBBN w - - 0 1",
    "rqknrnbb/pppppppp/8/8/8/8/PPPPPPPP/RQKNRNBB w - - 0 1",
    "bbrkqnrn/pppppppp/8/8/8/8/PPPPPPPP/BBRKQNRN w - - 0 1",
    "brkbqnrn/pppppppp/8/8/8/8/PPPPPPPP/BRKBQNRN w - - 0 1",
    "brkqnbrn/pppppppp/8/8/8/8/PPPPPPPP/BRKQNBRN w - - 0 1",
    "brkqnrnb/pppppppp/8/8/8/8/PPPPPPPP/BRKQNRNB w - - 0 1",
    "rbbkqnrn/pppppppp/8/8/8/8/PPPPPPPP/RBBKQNRN w - - 0 1",
    "rkbbqnrn/pppppppp/8/8/8/8/PPPPPPPP/RKBBQNRN w - - 0 1",
    "rkbqnbrn/pppppppp/8/8/8/8/PPPPPPPP/RKBQNBRN w - - 0 1",
    "rkbqnrnb/pppppppp/8/8/8/8/PPPPPPPP/RKBQNRNB w - - 0 1",
    "rbkqbnrn/pppppppp/8/8/8/8/PPPPPPPP/RBKQBNRN w - - 0 1",
    "rkqbbnrn/pppppppp/8/8/8/8/PPPPPPPP/RKQBBNRN w - - 0 1",
    "rkqnbbrn/pppppppp/8/8/8/8/PPPPPPPP/RKQNBBRN w - - 0 1",
    "rkqnbrnb/pppppppp/8/8/8/8/PPPPPPPP/RKQNBRNB w - - 0 1",
    "rbkqnrbn/pppppppp/8/8/8/8/PPPPPPPP/RBKQNRBN w - - 0 1",
    "rkqbnrbn/pppppppp/8/8/8/8/PPPPPPPP/RKQBNRBN w - - 0 1",
    "rkqnrbbn/pppppppp/8/8/8/8/PPPPPPPP/RKQNRBBN w - - 0 1",
    "rkqnrnbb/pppppppp/8/8/8/8/PPPPPPPP/RKQNRNBB w - - 0 1",
    "bbrknqrn/pppppppp/8/8/8/8/PPPPPPPP/BBRKNQRN w - - 0 1",
    "brkbnqrn/pppppppp/8/8/8/8/PPPPPPPP/BRKBNQRN w - - 0 1",
    "brknqbrn/pppppppp/8/8/8/8/PPPPPPPP/BRKNQBRN w - - 0 1",
    "brknqrnb/pppppppp/8/8/8/8/PPPPPPPP/BRKNQRNB w - - 0 1",
    "rbbknqrn/pppppppp/8/8/8/8/PPPPPPPP/RBBKNQRN w - - 0 1",
    "rkbbnqrn/pppppppp/8/8/8/8/PPPPPPPP/RKBBNQRN w - - 0 1",
    "rkbnqbrn/pppppppp/8/8/8/8/PPPPPPPP/RKBNQBRN w - - 0 1",
    "rkbnqrnb/pppppppp/8/8/8/8/PPPPPPPP/RKBNQRNB w - - 0 1",
    "rbknbqrn/pppppppp/8/8/8/8/PPPPPPPP/RBKNBQRN w - - 0 1",
    "rknbbqrn/pppppppp/8/8/8/8/PPPPPPPP/RKNBBQRN w - - 0 1",
    "rknqbbrn/pppppppp/8/8/8/8/PPPPPPPP/RKNQBBRN w - - 0 1",
    "rknqbrnb/pppppppp/8/8/8/8/PPPPPPPP/RKNQBRNB w - - 0 1",
    "rbknqrbn/pppppppp/8/8/8/8/PPPPPPPP/RBKNQRBN w - - 0 1",
    "rknbqrbn/pppppppp/8/8/8/8/PPPPPPPP/RKNBQRBN w - - 0 1",
    "rknqrbbn/pppppppp/8/8/8/8/PPPPPPPP/RKNQRBBN w - - 0 1",
    "rknqrnbb/pppppppp/8/8/8/8/PPPPPPPP/RKNQRNBB w - - 0 1",
    "bbrknrqn/pppppppp/8/8/8/8/PPPPPPPP/BBRKNRQN w - - 0 1",
    "brkbnrqn/pppppppp/8/8/8/8/PPPPPPPP/BRKBNRQN w - - 0 1",
    "brknrbqn/pppppppp/8/8/8/8/PPPPPPPP/BRKNRBQN w - - 0 1",
    "brknrqnb/pppppppp/8/8/8/8/PPPPPPPP/BRKNRQNB w - - 0 1",
    "rbbknrqn/pppppppp/8/8/8/8/PPPPPPPP/RBBKNRQN w - - 0 1",
    "rkbbnrqn/pppppppp/8/8/8/8/PPPPPPPP/RKBBNRQN w - - 0 1",
    "rkbnrbqn/pppppppp/8/8/8/8/PPPPPPPP/RKBNRBQN w - - 0 1",
    "rkbnrqnb/pppppppp/8/8/8/8/PPPPPPPP/RKBNRQNB w - - 0 1",
    "rbknbrqn/pppppppp/8/8/8/8/PPPPPPPP/RBKNBRQN w - - 0 1",
    "rknbbrqn/pppppppp/8/8/8/8/PPPPPPPP/RKNBBRQN w - - 0 1",
    "rknrbbqn/pppppppp/8/8/8/8/PPPPPPPP/RKNRBBQN w - - 0 1",
    "rknrbqnb/pppppppp/8/8/8/8/PPPPPPPP/RKNRBQNB w - - 0 1",
    "rbknrqbn/pppppppp/8/8/8/8/PPPPPPPP/RBKNRQBN w - - 0 1",
    "rknbrqbn/pppppppp/8/8/8/8/PPPPPPPP/RKNBRQBN w - - 0 1",
    "rknrqbbn/pppppppp/8/8/8/8/PPPPPPPP/RKNRQBBN w - - 0 1",
    "rknrqnbb/pppppppp/8/8/8/8/PPPPPPPP/RKNRQNBB w - - 0 1",
    "bbrknrnq/pppppppp/8/8/8/8/PPPPPPPP/BBRKNRNQ w - - 0 1",
    "brkbnrnq/pppppppp/8/8/8/8/PPPPPPPP/BRKBNRNQ w - - 0 1",
    "brknrbnq/pppppppp/8/8/8/8/PPPPPPPP/BRKNRBNQ w - - 0 1",
    "brknrnqb/pppppppp/8/8/8/8/PPPPPPPP/BRKNRNQB w - - 0 1",
    "rbbknrnq/pppppppp/8/8/8/8/PPPPPPPP/RBBKNRNQ w - - 0 1",
    "rkbbnrnq/pppppppp/8/8/8/8/PPPPPPPP/RKBBNRNQ w - - 0 1",
    "rkbnrbnq/pppppppp/8/8/8/8/PPPPPPPP/RKBNRBNQ w - - 0 1",
    "rkbnrnqb/pppppppp/8/8/8/8/PPPPPPPP/RKBNRNQB w - - 0 1",
    "rbknbrnq/pppppppp/8/8/8/8/PPPPPPPP/RBKNBRNQ w - - 0 1",
    "rknbbrnq/pppppppp/8/8/8/8/PPPPPPPP/RKNBBRNQ w - - 0 1",
    "rknrbbnq/pppppppp/8/8/8/8/PPPPPPPP/RKNRBBNQ w - - 0 1",
    "rknrbnqb/pppppppp/8/8/8/8/PPPPPPPP/RKNRBNQB w - - 0 1",
    "rbknrnbq/pppppppp/8/8/8/8/PPPPPPPP/RBKNRNBQ w - - 0 1",
    "rknbrnbq/pppppppp/8/8/8/8/PPPPPPPP/RKNBRNBQ w - - 0 1",
    "rknrnbbq/pppppppp/8/8/8/8/PPPPPPPP/RKNRNBBQ w - - 0 1",
    "rknrnqbb/pppppppp/8/8/8/8/PPPPPPPP/RKNRNQBB w - - 0 1",
    "bbqrkrnn/pppppppp/8/8/8/8/PPPPPPPP/BBQRKRNN w - - 0 1",
    "bqrbkrnn/pppppppp/8/8/8/8/PPPPPPPP/BQRBKRNN w - - 0 1",
    "bqrkrbnn/pppppppp/8/8/8/8/PPPPPPPP/BQRKRBNN w - - 0 1",
    "bqrkrnnb/pppppppp/8/8/8/8/PPPPPPPP/BQRKRNNB w - - 0 1",
    "qbbrkrnn/pppppppp/8/8/8/8/PPPPPPPP/QBBRKRNN w - - 0 1",
    "qrbbkrnn/pppppppp/8/8/8/8/PPPPPPPP/QRBBKRNN w - - 0 1",
    "qrbkrbnn/pppppppp/8/8/8/8/PPPPPPPP/QRBKRBNN w - - 0 1",
    "qrbkrnnb/pppppppp/8/8/8/8/PPPPPPPP/QRBKRNNB w - - 0 1",
    "qbrkbrnn/pppppppp/8/8/8/8/PPPPPPPP/QBRKBRNN w - - 0 1",
    "qrkbbrnn/pppppppp/8/8/8/8/PPPPPPPP/QRKBBRNN w - - 0 1",
    "qrkrbbnn/pppppppp/8/8/8/8/PPPPPPPP/QRKRBBNN w - - 0 1",
    "qrkrbnnb/pppppppp/8/8/8/8/PPPPPPPP/QRKRBNNB w - - 0 1",
    "qbrkrnbn/pppppppp/8/8/8/8/PPPPPPPP/QBRKRNBN w - - 0 1",
    "qrkbrnbn/pppppppp/8/8/8/8/PPPPPPPP/QRKBRNBN w - - 0 1",
    "qrkrnbbn/pppppppp/8/8/8/8/PPPPPPPP/QRKRNBBN w - - 0 1",
    "qrkrnnbb/pppppppp/8/8/8/8/PPPPPPPP/QRKRNNBB w - - 0 1",
    "bbrqkrnn/pppppppp/8/8/8/8/PPPPPPPP/BBRQKRNN w - - 0 1",
    "brqbkrnn/pppppppp/8/8/8/8/PPPPPPPP/BRQBKRNN w - - 0 1",
    "brqkrbnn/pppppppp/8/8/8/8/PPPPPPPP/BRQKRBNN w - - 0 1",
    "brqkrnnb/pppppppp/8/8/8/8/PPPPPPPP/BRQKRNNB w - - 0 1",
    "rbbqkrnn/pppppppp/8/8/8/8/PPPPPPPP/RBBQKRNN w - - 0 1",
    "rqbbkrnn/pppppppp/8/8/8/8/PPPPPPPP/RQBBKRNN w - - 0 1",
    "rqbkrbnn/pppppppp/8/8/8/8/PPPPPPPP/RQBKRBNN w - - 0 1",
    "rqbkrnnb/pppppppp/8/8/8/8/PPPPPPPP/RQBKRNNB w - - 0 1",
    "rbqkbrnn/pppppppp/8/8/8/8/PPPPPPPP/RBQKBRNN w - - 0 1",
    "rqkbbrnn/pppppppp/8/8/8/8/PPPPPPPP/RQKBBRNN w - - 0 1",
    "rqkrbbnn/pppppppp/8/8/8/8/PPPPPPPP/RQKRBBNN w - - 0 1",
    "rqkrbnnb/pppppppp/8/8/8/8/PPPPPPPP/RQKRBNNB w - - 0 1",
    "rbqkrnbn/pppppppp/8/8/8/8/PPPPPPPP/RBQKRNBN w - - 0 1",
    "rqkbrnbn/pppppppp/8/8/8/8/PPPPPPPP/RQKBRNBN w - - 0 1",
    "rqkrnbbn/pppppppp/8/8/8/8/PPPPPPPP/RQKRNBBN w - - 0 1",
    "rqkrnnbb/pppppppp/8/8/8/8/PPPPPPPP/RQKRNNBB w - - 0 1",
    "bbrkqrnn/pppppppp/8/8/8/8/PPPPPPPP/BBRKQRNN w - - 0 1",
    "brkbqrnn/pppppppp/8/8/8/8/PPPPPPPP/BRKBQRNN w - - 0 1",
    "brkqrbnn/pppppppp/8/8/8/8/PPPPPPPP/BRKQRBNN w - - 0 1",
    "brkqrnnb/pppppppp/8/8/8/8/PPPPPPPP/BRKQRNNB w - - 0 1",
    "rbbkqrnn/pppppppp/8/8/8/8/PPPPPPPP/RBBKQRNN w - - 0 1",
    "rkbbqrnn/pppppppp/8/8/8/8/PPPPPPPP/RKBBQRNN w - - 0 1",
    "rkbqrbnn/pppppppp/8/8/8/8/PPPPPPPP/RKBQRBNN w - - 0 1",
    "rkbqrnnb/pppppppp/8/8/8/8/PPPPPPPP/RKBQRNNB w - - 0 1",
    "rbkqbrnn/pppppppp/8/8/8/8/PPPPPPPP/RBKQBRNN w - - 0 1",
    "rkqbbrnn/pppppppp/8/8/8/8/PPPPPPPP/RKQBBRNN w - - 0 1",
    "rkqrbbnn/pppppppp/8/8/8/8/PPPPPPPP/RKQRBBNN w - - 0 1",
    "rkqrbnnb/pppppppp/8/8/8/8/PPPPPPPP/RKQRBNNB w - - 0 1",
    "rbkqrnbn/pppppppp/8/8/8/8/PPPPPPPP/RBKQRNBN w - - 0 1",
    "rkqbrnbn/pppppppp/8/8/8/8/PPPPPPPP/RKQBRNBN w - - 0 1",
    "rkqrnbbn/pppppppp/8/8/8/8/PPPPPPPP/RKQRNBBN w - - 0 1",
    "rkqrnnbb/pppppppp/8/8/8/8/PPPPPPPP/RKQRNNBB w - - 0 1",
    "bbrkrqnn/pppppppp/8/8/8/8/PPPPPPPP/BBRKRQNN w - - 0 1",
    "brkbrqnn/pppppppp/8/8/8/8/PPPPPPPP/BRKBRQNN w - - 0 1",
    "brkrqbnn/pppppppp/8/8/8/8/PPPPPPPP/BRKRQBNN w - - 0 1",
    "brkrqnnb/pppppppp/8/8/8/8/PPPPPPPP/BRKRQNNB w - - 0 1",
    "rbbkrqnn/pppppppp/8/8/8/8/PPPPPPPP/RBBKRQNN w - - 0 1",
    "rkbbrqnn/pppppppp/8/8/8/8/PPPPPPPP/RKBBRQNN w - - 0 1",
    "rkbrqbnn/pppppppp/8/8/8/8/PPPPPPPP/RKBRQBNN w - - 0 1",
    "rkbrqnnb/pppppppp/8/8/8/8/PPPPPPPP/RKBRQNNB w - - 0 1",
    "rbkrbqnn/pppppppp/8/8/8/8/PPPPPPPP/RBKRBQNN w - - 0 1",
    "rkrbbqnn/pppppppp/8/8/8/8/PPPPPPPP/RKRBBQNN w - - 0 1",
    "rkrqbbnn/pppppppp/8/8/8/8/PPPPPPPP/RKRQBBNN w - - 0 1",
    "rkrqbnnb/pppppppp/8/8/8/8/PPPPPPPP/RKRQBNNB w - - 0 1",
    "rbkrqnbn/pppppppp/8/8/8/8/PPPPPPPP/RBKRQNBN w - - 0 1",
    "rkrbqnbn/pppppppp/8/8/8/8/PPPPPPPP/RKRBQNBN w - - 0 1",
    "rkrqnbbn/pppppppp/8/8/8/8/PPPPPPPP/RKRQNBBN w - - 0 1",
    "rkrqnnbb/pppppppp/8/8/8/8/PPPPPPPP/RKRQNNBB w - - 0 1",
    "bbrkrnqn/pppppppp/8/8/8/8/PPPPPPPP/BBRKRNQN w - - 0 1",
    "brkbrnqn/pppppppp/8/8/8/8/PPPPPPPP/BRKBRNQN w - - 0 1",
    "brkrnbqn/pppppppp/8/8/8/8/PPPPPPPP/BRKRNBQN w - - 0 1",
    "brkrnqnb/pppppppp/8/8/8/8/PPPPPPPP/BRKRNQNB w - - 0 1",
    "rbbkrnqn/pppppppp/8/8/8/8/PPPPPPPP/RBBKRNQN w - - 0 1",
    "rkbbrnqn/pppppppp/8/8/8/8/PPPPPPPP/RKBBRNQN w - - 0 1",
    "rkbrnbqn/pppppppp/8/8/8/8/PPPPPPPP/RKBRNBQN w - - 0 1",
    "rkbrnqnb/pppppppp/8/8/8/8/PPPPPPPP/RKBRNQNB w - - 0 1",
    "rbkrbnqn/pppppppp/8/8/8/8/PPPPPPPP/RBKRBNQN w - - 0 1",
    "rkrbbnqn/pppppppp/8/8/8/8/PPPPPPPP/RKRBBNQN w - - 0 1",
    "rkrnbbqn/pppppppp/8/8/8/8/PPPPPPPP/RKRNBBQN w - - 0 1",
    "rkrnbqnb/pppppppp/8/8/8/8/PPPPPPPP/RKRNBQNB w - - 0 1",
    "rbkrnqbn/pppppppp/8/8/8/8/PPPPPPPP/RBKRNQBN w - - 0 1",
    "rkrbnqbn/pppppppp/8/8/8/8/PPPPPPPP/RKRBNQBN w - - 0 1",
    "rkrnqbbn/pppppppp/8/8/8/8/PPPPPPPP/RKRNQBBN w - - 0 1",
    "rkrnqnbb/pppppppp/8/8/8/8/PPPPPPPP/RKRNQNBB w - - 0 1",
    "bbrkrnnq/pppppppp/8/8/8/8/PPPPPPPP/BBRKRNNQ w - - 0 1",
    "brkbrnnq/pppppppp/8/8/8/8/PPPPPPPP/BRKBRNNQ w - - 0 1",
    "brkrnbnq/pppppppp/8/8/8/8/PPPPPPPP/BRKRNBNQ w - - 0 1",
    "brkrnnqb/pppppppp/8/8/8/8/PPPPPPPP/BRKRNNQB w - - 0 1",
    "rbbkrnnq/pppppppp/8/8/8/8/PPPPPPPP/RBBKRNNQ w - - 0 1",
    "rkbbrnnq/pppppppp/8/8/8/8/PPPPPPPP/RKBBRNNQ w - - 0 1",
    "rkbrnbnq/pppppppp/8/8/8/8/PPPPPPPP/RKBRNBNQ w - - 0 1",
    "rkbrnnqb/pppppppp/8/8/8/8/PPPPPPPP/RKBRNNQB w - - 0 1",
    "rbkrbnnq/pppppppp/8/8/8/8/PPPPPPPP/RBKRBNNQ w - - 0 1",
    "rkrbbnnq/pppppppp/8/8/8/8/PPPPPPPP/RKRBBNNQ w - - 0 1",
    "rkrnbbnq/pppppppp/8/8/8/8/PPPPPPPP/RKRNBBNQ w - - 0 1",
    "rkrnbnqb/pppppppp/8/8/8/8/PPPPPPPP/RKRNBNQB w - - 0 1",
    "rbkrnnbq/pppppppp/8/8/8/8/PPPPPPPP/RBKRNNBQ w - - 0 1",
    "rkrbnnbq/pppppppp/8/8/8/8/PPPPPPPP/RKRBNNBQ w - - 0 1",
    "rkrnnbbq/pppppppp/8/8/8/8/PPPPPPPP/RKRNNBBQ w - - 0 1",
    "rkrnnqbb/pppppppp/8/8/8/8/PPPPPPPP/RKRNNQBB w - - 0 1",
];
