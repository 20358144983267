import { writable } from "svelte/store";
export const _roomId$ = writable(undefined);
export const roomId$ = writable(undefined);
export const _playerId$ = writable(undefined);
export const playerId$ = writable(undefined);
export const settings$ = writable({
    minsPerSide: 3,
    startingPosition: "standard",
    fischerSeed: 0,
});
export const wizard$ = writable({ stepn: 1, status: "todo" });
export const spots$ = writable({});
export const gamen$ = writable(0);
export const crazy$ = writable({});
export const acg$ = writable({
    fen: "",
    lastMove: [],
    promotions: {},
});
export const bcg$ = writable({
    fen: "",
    lastMove: [],
    promotions: {},
});
