import { Chess } from "chess.js";
function cgchpiece(piece) {
    const chPieceType = piece.role == "knight" ? "n" : piece.role.charAt(0);
    return {
        type: chPieceType,
        color: piece.color.charAt(0),
    };
}
function chcgpiece(piece) {
    const color = piece.color == "w" ? "white" : "black";
    let role = {
        "p": "pawn",
        "b": "bishop",
        "n": "knight",
        "r": "rook",
        "q": "queen",
        "k": "king"
    }[piece.type];
    return { role, color };
}
export function fenNextTurn(fen) {
    // rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR b KQkq e3 0 1
    let fenParts = fen.split(" ");
    const currentTurn = fenParts[1];
    fenParts[1] = currentTurn === "w" ? "b" : "w"; // change turn
    fenParts[3] = "-"; // enpassant
    fenParts[5] = currentTurn === "b" ? parseInt(fenParts[5]) + 1 + "" : fenParts[5];
    return fenParts.join(" ");
}
export function fenMoveNumber(fen) {
    let fenParts = fen.split(" ");
    const turn = fenParts[1] === "w" ? 0 : 1;
    const fullmove = parseInt(fenParts[5]);
    return 2 * fullmove + turn;
}
export function moves(chess) {
    const dests = new Map();
    chess.SQUARES.forEach((s) => {
        const ms = chess.moves({ square: s, verbose: true });
        if (ms.length) {
            dests.set(s, ms.map((m) => m.to));
        }
    });
    // @ts-ignore
    return dests;
}
export function move(chess, src, dest) {
    const chmove = chess.move({ from: src, to: dest, promotion: "q" });
    if (!chmove)
        return undefined;
    return chmove;
}
export function put(chess, piece, dest) {
    if (!puts(chess, [piece], [dest]))
        return undefined;
    chess.put(cgchpiece(piece), dest);
    // trick chess.js to change turn after a piece drop
    const newFen = fenNextTurn(chess.fen());
    chess.load(newFen);
    return newFen;
}
export function puts(chess, pieces, squares) {
    if (!squares)
        squares = chess.SQUARES;
    // square is valid only if already unoccupied
    squares = squares.filter((square) => chess.get(square) == null);
    const inCheck = chess.in_check();
    // if in check, put is valid only if it removes the check
    const fen = chess.fen();
    const temp = new Chess();
    const apiece = pieces.find((piece) => {
        const asquare = squares.find((dest) => {
            if (piece.role == "pawn" && ["1", "8"].includes(dest.charAt(1)))
                return false;
            if (!inCheck)
                return true;
            temp.load(fen);
            temp.put(cgchpiece(piece), dest);
            // valid put only if putting the piece removes the check
            return !temp.in_check();
        });
        if (asquare)
            return true;
        else
            return false;
    });
    if (apiece)
        return true;
    else
        return undefined;
}
export function inCrazyCheckmate(chess, spares) {
    if (!chess.in_checkmate())
        return false;
    const turnColor = chess.turn() === "w" ? "white" : "black";
    const pieces = Object.keys(spares)
        .filter((k) => {
        if (!["pawn", "knight", "bishop", "rook", "queen"].includes(k))
            return false;
        if (spares[k] <= 0)
            return false;
        return true;
    })
        .map((r) => {
        return { role: r, color: turnColor };
    });
    if (puts(chess, pieces))
        return false;
    return true;
}
